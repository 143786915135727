// Frontend: src/pages/NotificationsPage.js
import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography, Tooltip, Divider } from "@mui/material";
import { Delete, CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import notificationApi from "../../utils/notifications";
import styled from "@emotion/styled";
import { Bell, Server, UserPlus, Home } from "react-feather";
import { SvgIcon } from "@mui/material";

const NotificationHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.theme.spacing(2)};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

const NotificationList = styled(List)`
  max-height: 80vh;
  overflow-y: auto;
`;

const Avatar = styled("div")`
  background: ${(props) => props.theme.palette.primary.main};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function getIconComponent(iconName) {
  const icons = {
    Bell: Bell,
    Server: Server,
    UserPlus: UserPlus,
    Home: Home,
  };
  const IconComponent = icons[iconName] || Bell;
  return (
    <SvgIcon fontSize="small">
      <IconComponent />
    </SvgIcon>
  );
}

function NotificationItem({ notification, onMarkRead, onMarkUnread, onDelete }) {
  const { id, title, description, icon, isRead } = notification;

  const handleMark = () => {
    if (isRead) {
      onMarkUnread(id);
    } else {
      onMarkRead(id);
    }
  };

  const handleDelete = () => {
    onDelete(id);
  };

  return (
    <ListItem alignItems="flex-start" divider sx={{ backgroundColor: isRead ? "inherit" : "rgba(0, 0, 255, 0.1)" }}>
      <ListItemAvatar>
        <Avatar>{getIconComponent(icon)}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant="subtitle1" color="textPrimary" fontWeight={isRead ? "normal" : "bold"}>
            {title}
          </Typography>
        }
        secondary={description}
      />
      <Box>
        <Tooltip title={isRead ? "Mark as Unread" : "Mark as Read"}>
          <IconButton onClick={handleMark} size="large">
            {isRead ? <RadioButtonUnchecked /> : <CheckCircle />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={handleDelete} size="large">
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    </ListItem>
  );
}

function NotificationsPage() {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const loadNotifications = async () => {
    try {
      const data = await notificationApi.fetchNotifications();
      setNotifications(data);
      setUnreadCount(data.filter((n) => !n.isRead).length);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadNotifications();
  }, []);

  const handleMarkRead = async (id) => {
    try {
      const success = await notificationApi.markAsRead(id);
      if (success) {
        setNotifications((prev) => prev.map((n) => (n.id === id ? { ...n, isRead: true } : n)));
        setUnreadCount((prev) => prev - 1);
      }
    } catch (error) {
      console.error("Failed to mark as read:", error);
    }
  };

  const handleMarkUnread = async (id) => {
    try {
      const success = await notificationApi.markAsUnread(id);
      if (success) {
        setNotifications((prev) => prev.map((n) => (n.id === id ? { ...n, isRead: false } : n)));
        setUnreadCount((prev) => prev + 1);
      }
    } catch (error) {
      console.error("Failed to mark as unread:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const success = await notificationApi.deleteNotification(id);
      if (success) {
        setNotifications((prev) => prev.filter((n) => n.id !== id));
        setUnreadCount((prev) => prev - (notifications.find((n) => n.id === id)?.isRead ? 0 : 1));
      }
    } catch (error) {
      console.error("Failed to delete notification:", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      const success = await notificationApi.markAllAsRead();
      if (success) {
        setNotifications((prev) => prev.map((n) => ({ ...n, isRead: true })));
        setUnreadCount(0);
      }
    } catch (error) {
      console.error("Failed to mark all as read:", error);
    }
  };

  return (
    <Box p={2}>
      <NotificationHeader>
        <Typography variant="h6">Notifications</Typography>
        <Button variant="contained" onClick={handleMarkAllAsRead} disabled={unreadCount === 0}>
          Mark All as Read
        </Button>
      </NotificationHeader>
      <Divider />
      {loading ? (
        <Typography variant="body1" align="center" mt={2}>
          Loading...
        </Typography>
      ) : (
        <NotificationList>
          {notifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              onMarkRead={handleMarkRead}
              onMarkUnread={handleMarkUnread}
              onDelete={handleDelete}
            />
          ))}
        </NotificationList>
      )}
    </Box>
  );
}

export default NotificationsPage;
