import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
// import SelectWrapperDB from "../../components/SelectBoxDataGridCell";
import DataGridExport from "../../components/DataGridExport";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const UnassignedInvoices = () => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/v1/customerInvoicesNoClaimId")
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching customer invoices without claim ID:", error);
      });
  }, []);

  const [supplierData, setSupplierData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      axios.get("/api/v1/suppliers").then((response) => setSupplierData(response.data));
    }
    fetchData();
  }, []);

  // console.table(supplierData);

  const [currencyData, setCurrencyData] = useState([]);

  useEffect(() => {
    axios.get("/api/v1/currencies").then((response) => setCurrencyData(response.data));
  }, []);

  const [productData, setProductData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      axios.get("/api/v1/productTypes").then((response) => setProductData(response.data));
    }
    fetchData();
  }, []);

  // const [invoiceLabelData, setInvoiceLabeData] = useState([]);

  // useEffect(() => {
  //   async function fetchData() {
  //     axios
  //       .get("/api/v1/invoiceLabels")
  //       .then((response) => setInvoiceLabeData(response.data));
  //   }
  //   fetchData();
  // }, []);

  const [invoiceStateData, setInvoiceStateData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      axios.get("/api/v1/invoiceStates").then((response) => setInvoiceStateData(response.data));
    }
    fetchData();
  }, []);

  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      axios.get("/api/v1/countries").then((response) => setCountryData(response.data));
    }
    fetchData();
  }, []);

  // console.table(tableData);
  console.log(supplierData);
  const columns = [
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      flex: 0.5,
      hide: false,
    },
    {
      field: "invoiceDate",
      headerName: "Invoice Date",
      flex: 0.5,
      valueFormatter: (params) => moment(params?.value).format("DD-MM-YYYY"),
    },
    {
      field: "supplierId",
      headerName: "Supplier",
      flex: 0.5,
      valueGetter: (params) => {
        const foundSupplier = supplierData.find((supplier) => supplier.supplierId === params.row.supplierId);
        return foundSupplier ? foundSupplier.supplierName : "";
      },
    },
    {
      field: "productTypeId",
      headerName: "Product",
      flex: 0.5,
      valueGetter: (params) =>
        productData.length > 0
          ? productData.find((product) => product.productTypeId === params.row.claimProductTypeId)?.productShortDescription
          : "",
    },
    {
      field: "countryCode",
      headerName: "Country",
      flex: 0.5,
      valueGetter: (params) =>
        countryData.length > 0 ? countryData.find((country) => country.countryId === params.row.countryCode).countryCode : "",
    },
    {
      field: "currencyId",
      headerName: "Currency",
      flex: 0.5,
      valueGetter: (params) =>
        currencyData.length > 0 ? currencyData.find((cur) => cur.currencyId === params.row.currencyId).isoCode : "",
    },
    {
      field: "invoiceStateId",
      headerName: "Invoice State",
      flex: 0.5,
      valueGetter: (params) =>
        invoiceStateData.length > 0
          ? invoiceStateData.find((cur) => cur.invoiceStateId === params.row.invoiceStateId)?.invoiceStateShortCode
          : "",
    },
    {
      field: "invoiceGrossAmount",
      headerName: "Net Amount",
      flex: 0.5,
      valueFormatter: ({ value }) => {
        return value.toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
      align: "right",
      paddingRight: "10px",
    },
    {
      field: "invoiceVATAmountLocalCurrency",
      headerName: "VAT Amount Local Currency",
      flex: 0.5,
      valueFormatter: ({ value }) => {
        return value.toLocaleString("de-DE", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      },
      align: "right",
      paddingRight: "10px",
    },
    // {
    //   field: "InvoiceState",
    //   headerName: "Invoice State",
    //   flex: 0.5,
    //   valueGetter: (params) =>
    //     invoiceStateData.length > 0
    //       ? invoiceStateData.find(
    //           (cur) => cur.invoiceStateId === params.row.invoiceStateId
    //         ).invoiceStateShortCode
    //       : "",
    // },
    // {
    //   field: "InvoiceState",
    //   headerName: "Invoice State",
    //   flex: 0.6,
    //   renderCell: (params) => {
    //     const handleChange = (selectedKey) => {
    //       // const rowData = params.row;
    //       // const updatedRowData = {
    //       //   ...rowData,
    //       //   invoiceStateId: selectedKey,
    //       // };
    //       // handleInvoiceStateUpdate(updatedRowData);
    //     };

    //     return (
    //       <SelectWrapperDB
    //         // disabled={selectedClaimPreview.claimStateId == 9 ? true : false}
    //         disabled
    //         name="invoiceState"
    //         rowIndex={params.api.getRowIndex(params.row.__rowNum__)}
    //         selectedKey={params.row.invoiceStateId}
    //         options={JSON.parse(
    //           JSON.stringify(
    //             JSON.parse(
    //               JSON.stringify(invoiceStateData)
    //                 .split('"invoiceStateId":')
    //                 .join('"key":')
    //             )
    //           )
    //             .split('"invoiceStateShortCode":')
    //             .join('"value":')
    //         )}
    //         handleChange={handleChange}
    //       />
    //     );
    //   },
    // },
    // {
    //   field: "InvoiceLabel",
    //   headerName: "Invoice Label",
    //   flex: 0.6,
    //   renderCell: (params) => {
    //     const handleChange = (selectedKey) => {
    //       // const rowData = params.row;
    //       // const updatedRowData = {
    //       //   ...rowData,
    //       //   invoiceLabelId: selectedKey,
    //       // };
    //       // handleInvoiceLabelUpdate(updatedRowData);
    //     };

    //     return (
    //       <SelectWrapperDB
    //         disabled
    //         name="invoiceLabel"
    //         rowIndex={params.api.getRowIndex(params.row.__rowNum__)}
    //         selectedKey={params.row.invoiceLabelId}
    //         options={JSON.parse(
    //           JSON.stringify(
    //             JSON.parse(
    //               JSON.stringify(invoiceLabelData)
    //                 .split('"invoiceLabelId":')
    //                 .join('"key":')
    //             )
    //           )
    //             .split('"invoiceLabelShortCode":')
    //             .join('"value":')
    //         )}
    //         handleChange={handleChange}
    //       />
    //     );
    //   },
    // },

    {
      field: "excludeFromClaim",
      headerName: "Exclude from Claim",
      flex: 0.5,
      hide: true,
    },
  ];

  return (
    <React.Fragment>
      <Helmet title="Customers" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Customers
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/">
              Pages
            </Link>
            <Typography>Customers</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent
          pb={1}
          sx={{
            marginBottom: 5,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Customers Table
          </Typography>
          <Typography variant="body2" gutterBottom>
            In this table you can search and view customers. You can use the below options to customize the customer grid. Test.
          </Typography>
        </CardContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={tableData} columns={columns} />
            </div>
            <DataGrid
              getRowId={(row) => row.customerInvoiceId}
              components={{ Toolbar: GridToolbar }}
              density="compact"
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: false },
                  printOptions: { disableToolbarButton: false },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              experimentalFeatures={{ newEditingApi: true }}
              rows={tableData}
              columns={columns}
              pageSize={15}
              sx={{
                height: 667,
                width: "100%",
                borderRadius: 1,
                backgroundColor: "background.paper",
                boxShadow: 2,
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                marginTop: 0,
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default UnassignedInvoices;
