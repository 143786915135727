import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Notifications
import NotificationsPage from "./pages/Notifications/Notifications";

// Table customers
import CustomerTable from "./pages/customers/list";
// import CustomerSearch from "./pages/customers/search";
import CustomerAdd from "./pages/customers/addcustomer";
import CustomerOverview from "./pages/customers/customeroverview";
import CustomerUploadedFiles from "./pages/customers/CustomerUploadedFiles";

//Invoices
import InvoicesAdd from "./pages/invoices/AddInvoices";
import UnassignedInvoices from "./pages/invoices/unassignedinvoices";
import AllInvoices from "./pages/invoices/AllInvoices";
import AddAutomatedInvoices from "./pages/invoices/AddAutomatedInvoices";
import CheckAutomatedInvoices from "./pages/invoices/CheckAutomatedInvoices";

// Claims
import AllClaims from "./pages/claims/AllClaims";
import AllExciseClaims from "./pages/claims/AllExciseClaims";

// Settings
import CompanySettings from "./pages/settings/companysettings/default";
import GlobalSettingsCountries from "./pages/settings/globalsettings/countries";
import GlobalSettingsClaimStates from "./pages/settings/globalsettings/claimstates";
import GlobalSettingsBankAccountTypes from "./pages/settings/globalsettings/bankaccounttypes";
import GlobalSettingsCurrencies from "./pages/settings/globalsettings/currencies";
import GlobalSettingsInvoiceLabels from "./pages/settings/globalsettings/invoicelabels";
import GlobalSettingsInvoiceStates from "./pages/settings/globalsettings/invoicestates";
import GlobalSettingsPaymentTypes from "./pages/settings/globalsettings/paymenttypes";
import GlobalSettingsRelationTypes from "./pages/settings/globalsettings/relationtypes";
import GlobalSettingsProductTypes from "./pages/settings/globalsettings/producttypes";
import GlobalSettingsSuppliers from "./pages/settings/globalsettings/suppliers";
import AccountManagers from "./pages/settings/portalsettings/AccountManagers";

// // Layouts
// import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
// import DocLayout from "./layouts/Doc";
// import PresentationLayout from "./layouts/Presentation";

// // Guards
// import AuthGuard from "./components/guards/AuthGuard";

// // Auth components
// import SignIn from "./pages/auth/SignIn";
// import SignUp from "./pages/auth/SignUp";
// import ResetPassword from "./pages/auth/ResetPassword";
// import Page404 from "./pages/auth/Page404";
// import Page500 from "./pages/auth/Page500";

// // Components
// import Accordion from "./pages/components/Accordion";
// import Alerts from "./pages/components/Alerts";
// import Avatars from "./pages/components/Avatars";
// import Badges from "./pages/components/Badges";
// import Buttons from "./pages/components/Buttons";
// import Cards from "./pages/components/Cards";
// import Chips from "./pages/components/Chips";
// import Dialogs from "./pages/components/Dialogs";
// import Lists from "./pages/components/Lists";
// import Menus from "./pages/components/Menus";
// import Pagination from "./pages/components/Pagination";
// import Progress from "./pages/components/Progress";
// import Snackbars from "./pages/components/Snackbars";
// import Tooltips from "./pages/components/Tooltips";

// // Form components
// import SelectionCtrls from "./pages/forms/SelectionControls";
// import Selects from "./pages/forms/Selects";
// import TextFields from "./pages/forms/TextFields";

// // Icon components
// import MaterialIcons from "./pages/icons/MaterialIcons";

// // Page components
// import Blank from "./pages/pages/Blank";
// import InvoiceDetails from "./pages/pages/InvoiceDetails";
// import InvoiceList from "./pages/pages/InvoiceList";
// import Orders from "./pages/pages/Orders";
// import Pricing from "./pages/pages/Pricing";
// import Settings from "./pages/pages/Settings";
// import Projects from "./pages/pages/Projects";
// import Chat from "./pages/pages/Chat";

// // Table components
// import SimpleTable from "./pages/tables/SimpleTable";
// import AdvancedTable from "./pages/tables/AdvancedTable";

// // Documentation
// import Welcome from "./pages/docs/Welcome";
// import GettingStarted from "./pages/docs/GettingStarted";
// import Routing from "./pages/docs/Routing";
// import Auth0 from "./pages/docs/auth/Auth0";
// import Cognito from "./pages/docs/auth/Cognito";
// import Firebase from "./pages/docs/auth/Firebase";
// import JWT from "./pages/docs/auth/JWT";
// import Guards from "./pages/docs/Guards";
// import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
// import Deployment from "./pages/docs/Deployment";
// import Theming from "./pages/docs/Theming";
// import APICalls from "./pages/docs/APICalls";
// import Redux from "./pages/docs/Redux";
// import Internationalization from "./pages/docs/Internationalization";
// import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
// import Support from "./pages/docs/Support";
// import Changelog from "./pages/docs/Changelog";

// // Landing
// import Landing from "./pages/presentation/Landing";

// // Protected routes
// import ProtectedPage from "./pages/protected/ProtectedPage";

// // Dashboard components
const Default = async(() => import("./pages/dashboard/Default"));
// const Analytics = async(() => import("./pages/dashboards/Analytics"));
// const SaaS = async(() => import("./pages/dashboards/SaaS"));

// // Form components
// const Pickers = async(() => import("./pages/forms/Pickers"));
// const Editors = async(() => import("./pages/forms/Editors"));
// const Formik = async(() => import("./pages/forms/Formik"));

// // Icon components
// const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));
// const Profile = async(() => import("./pages/pages/Profile"));
// const Tasks = async(() => import("./pages/pages/Tasks"));
// const Calendar = async(() => import("./pages/pages/Calendar"));

// // Table components
// const DataGrid = async(() => import("./pages/tables/DataGrid"));

// // Chart components
// const Chartjs = async(() => import("./pages/charts/Chartjs"));
// const ApexCharts = async(() => import("./pages/charts/ApexCharts"));

// // Maps components
// const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
// const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

const routes = [
  // {
  //   path: "/",
  //   element: <PresentationLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Landing />,
  //     },
  //   ],
  // },
  {
    path: "/notifications",
    element: <NotificationsPage />,
  },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "dashboard/default",
        element: <Default />,
      },
      // {
      //   path: "analytics",
      //   element: <Analytics />,
      // },
      // {
      //   path: "saas",
      //   element: <SaaS />,
      // },
    ],
  },
  {
    path: "customers",
    element: <DashboardLayout />,
    children: [
      {
        path: "list",
        element: <CustomerTable />,
      },
      // {
      //   path: "search",
      //   element: <CustomerSearch />,
      // },
      {
        path: "addcustomer",
        element: <CustomerAdd />,
      },
      {
        path: "customeroverview",
        element: <CustomerOverview />,
      },
      {
        path: "CustomerUploadedFiles",
        element: <CustomerUploadedFiles />,
      },
    ],
  },
  {
    path: "Invoices",
    element: <DashboardLayout />,
    children: [
      {
        path: "AllInvoices",
        element: <AllInvoices />,
      },
      {
        path: "addinvoices",
        element: <InvoicesAdd />,
      },
      {
        path: "unassignedinvoices",
        element: <UnassignedInvoices />,
      },
      {
        path: "AddAutomatedInvoices",
        element: <AddAutomatedInvoices />,
      },
      {
        path: "CheckAutomatedInvoices",
        element: <CheckAutomatedInvoices />,
      },
    ],
  },
  {
    path: "Claims",
    element: <DashboardLayout />,
    children: [
      {
        path: "AllClaims",
        element: <AllClaims />,
      },
      {
        path: "AllExciseClaims",
        element: <AllExciseClaims />,
      },
    ],
  },
  {
    path: "settings/companysettings",
    element: <DashboardLayout />,
    children: [
      {
        path: "default",
        element: <CompanySettings />,
      },
    ],
  },
  {
    path: "settings/portalsettings",
    element: <DashboardLayout />,
    children: [
      {
        path: "AccountManagers",
        element: <AccountManagers />,
      },
    ],
  },
  {
    path: "settings/globalsettings",
    element: <DashboardLayout />,
    children: [
      {
        path: "bankaccounttypes",
        element: <GlobalSettingsBankAccountTypes />,
      },
      {
        path: "claimstates",
        element: <GlobalSettingsClaimStates />,
      },
      {
        path: "countries",
        element: <GlobalSettingsCountries />,
      },
      {
        path: "currencies",
        element: <GlobalSettingsCurrencies />,
      },
      {
        path: "invoicelabels",
        element: <GlobalSettingsInvoiceLabels />,
      },
      {
        path: "invoicestates",
        element: <GlobalSettingsInvoiceStates />,
      },
      {
        path: "paymenttypes",
        element: <GlobalSettingsPaymentTypes />,
      },
      {
        path: "producttypes",
        element: <GlobalSettingsProductTypes />,
      },
      {
        path: "relationtypes",
        element: <GlobalSettingsRelationTypes />,
      },
      {
        path: "suppliers",
        element: <GlobalSettingsSuppliers />,
      },
    ],
  },
];

export default routes;
