import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar, GridFooter } from "@mui/x-data-grid";
import AddClaimForm from "../../components/AddClaimForm";
import { Grid, Box, Card as MuiCard, Typography, Divider, IconButton, CircularProgress, Button } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import axios from "axios";
import ViewClaimDialog from "../../components/ViewClaimDialog";
import DataGridExport from "../../components/DataGridExport";
import SelectWrapper from "../../components/SelectBoxDataGridCell";

const Card = styled(MuiCard)(spacing);

const CustomerClaims = (props) => {
  const customerId = props.customerId;
  const customerNumber = props.customerNumber;
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  // Filter states
  const [selectedCountry, setSelectedCountry] = useState("any");
  const [selectedYear, setSelectedYear] = useState("any");
  const [selectedStartMonth, setSelectedStartMonth] = useState("any");
  const [selectedEndMonth, setSelectedEndMonth] = useState("any");
  const [selectedClaimState, setSelectedClaimState] = useState("any");

  // Handle filter changes
  const handleCountryValue = (value) => setSelectedCountry(value);
  const handleYearValue = (value) => setSelectedYear(value);
  const handleStartMonthValue = (value) => setSelectedStartMonth(value);
  const handleEndMonthValue = (value) => setSelectedEndMonth(value);
  const handleClaimStateValue = (value) => setSelectedClaimState(value);

  const monthArr = [
    { monthId: 1, monthText: "Jan" },
    { monthId: 2, monthText: "Feb" },
    { monthId: 3, monthText: "Mar" },
    { monthId: 4, monthText: "Apr" },
    { monthId: 5, monthText: "May" },
    { monthId: 6, monthText: "Jun" },
    { monthId: 7, monthText: "Jul" },
    { monthId: 8, monthText: "Aug" },
    { monthId: 9, monthText: "Sep" },
    { monthId: 10, monthText: "Oct" },
    { monthId: 11, monthText: "Nov" },
    { monthId: 12, monthText: "Dec" },
  ];

  // Fetch claims based on filters
  const fetchClaimsByEntityId = useCallback(() => {
    setLoading(true);
    axios
      .get("/api/v1/claimsByEntityId/" + customerId)
      .then((response) => {
        let claims = response.data;

        if (selectedCountry !== "any") {
          claims = claims.filter((claim) => claim.countryId === selectedCountry);
        }
        if (selectedYear !== "any") {
          claims = claims.filter((claim) => claim.year === parseInt(selectedYear));
        }
        if (selectedStartMonth !== "any") {
          claims = claims.filter((claim) => claim.startMonth === parseInt(selectedStartMonth));
        }

        if (selectedEndMonth !== "any") {
          claims = claims.filter((claim) => claim.endMonth === parseInt(selectedEndMonth));
        }

        if (selectedClaimState !== "any") {
          claims = claims.filter((claim) => claim.claimStateId === parseInt(selectedClaimState));
        }

        setTableData(claims);
      })
      .catch((error) => console.error("Error fetching claims by entity ID:", error))
      .finally(() => setLoading(false));
  }, [customerId, selectedCountry, selectedYear, selectedStartMonth, selectedEndMonth, selectedClaimState]);

  useEffect(() => {
    fetchClaimsByEntityId();
  }, [fetchClaimsByEntityId]);

  // Fetch country and claim state data
  const [countryData, setCountryData] = useState([]);
  const [claimStateData, setClaimStateData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get("/api/v1/countries");
      const claimStateResponse = await axios.get("/api/v1/claimStates");
      setCountryData(response.data);
      setClaimStateData(claimStateResponse.data);
    }
    fetchData();
  }, []);

  // Define columns
  const columns = [
    {
      field: "claimId",
      headerName: "Claim ID",
      width: 50,
      hide: true,
    },
    { field: "claimCode", headerName: "Claim Code", flex: 1 },
    {
      field: "TaxOfficeRefNo",
      headerName: "Ref. number",
      flex: 0.7,
      valueGetter: (params) => params.row.taxOfficeRefNo,
    },
    { field: "year", headerName: "Year", flex: 0.3 },
    {
      field: "claimPeriod",
      headerName: "Claim Periode",
      width: 120,
      valueGetter: (params) =>
        `${params.row.startMonth.toString().padStart(2, "0")}/${params.row.endMonth.toString().padStart(2, "0")}`,
    },
    {
      field: "claimStateId",
      headerName: "Claim State",
      flex: 0.6,
      valueGetter: (params) => claimStateData.find((state) => state.claimStateId === params.row.claimStateId)?.description || "",
    },
    {
      field: "countryId",
      headerName: "Country",
      flex: 0.3,
      valueGetter: (params) => countryData.find((country) => country.countryId === params.row.countryId)?.countryCode || "",
    },
    {
      field: "claimGrossAmount",
      headerName: "Net Amount",
      flex: 0.5,
      align: "right",
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "15px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "claimVATAmountLocCur",
      headerName: "VAT Local",
      flex: 0.5,
      align: "right",
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "15px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "claimVATAmountEUR",
      headerName: "VAT EUR",
      flex: 0.5,
      align: "right",
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "15px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              disabled={params.row.claimStateId !== 1}
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Claim",
                  subTitle: "Are you sure you want to delete " + params.row.claimCode + "?",
                  onConfirm: () => {
                    onDeleteConfirmed(e, params.row);
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewClaimDialog({
                  isOpen: true,
                  title: "View Claim",
                  params: params.row,
                  countryData: countryData,
                  custInfo: [{ customerId: customerId, customerNumber: customerNumber }],
                  subTitle: "View details for " + params.row.claimCode + "?",
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  // Notification and Dialog states
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [viewClaimDialog, setViewClaimDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    custInfo: [],
  });

  // Handle Edit Confirmation
  function onEditConfirmed(e, params) {
    window.location.reload(false);
    setViewClaimDialog({ ...viewClaimDialog, isOpen: false });
  }

  // Handle Delete Confirmation
  function onDeleteConfirmed(e, params) {
    axios
      .delete("/api/v1/claims/" + params.claimId)
      .then((response) => {
        axios.put("/api/v1/customerInvoices/updateInvoiceRemoveClaimId/" + params.claimId).then(() => {
          setNotify({
            isOpen: true,
            message: "Claim deleted successfully",
            type: "success",
          });
          fetchClaimsByEntityId();
        });
      })
      .catch((error) => {
        console.log(error);
        setNotify({
          isOpen: true,
          message: "Error deleting claim",
          type: "error",
        });
      })
      .finally(() => {
        setConfirmDialog({ ...confirmDialog, isOpen: false });
      });
  }

  // Row class names based on status
  const getRowClassName = (params) => {
    const { claimSequenceNumber, uploadStatus } = params.row;
    if (claimSequenceNumber > 0) {
      if (uploadStatus === "acknowledgement") {
        return "pastelGreen";
      } else if (uploadStatus !== "" && uploadStatus !== "acknowledgement") {
        return "pastelRed";
      } else if (uploadStatus === "") {
        return "pastelBlue";
      }
    }
    return "";
  };

  // Dynamic styles for row colors
  function addDynamicStyles(rules) {
    const styleEl = document.createElement("style");
    document.head.appendChild(styleEl);
    const styleSheet = styleEl.sheet;

    rules.forEach((rule) => {
      styleSheet.insertRule(rule, styleSheet.cssRules.length);
    });
  }

  useEffect(() => {
    addDynamicStyles([
      `.pastelGreen { background-color: #b2f2bb; }`, // pastel green
      `.pastelRed { background-color: #ffc9c9; }`, // pastel red
      `.pastelBlue { background-color: #a5d8ff; }`, // pastel blue
    ]);
  }, []);

  // Color Legend Component
  const ColorLegend = () => (
    <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2} mb={2}>
      <Box display="flex" alignItems="center" mr={2}>
        <Box width={15} height={15} bgcolor="#b2f2bb" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          Claim upload status OK
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mr={2}>
        <Box width={15} height={15} bgcolor="#a5d8ff" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          Claim upload in progress
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box width={15} height={15} bgcolor="#ffc9c9" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          Error while uploading claim
        </Typography>
      </Box>
    </Box>
  );

  // **New: State for selected rows and totals**
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedClaimCount, setSelectedClaimCount] = useState(0);
  const [selectedClaimTotalNetAmount, setSelectedClaimTotalNetAmount] = useState(0);
  const [selectedClaimTotalVATLocal, setSelectedClaimTotalVATLocal] = useState(0);
  const [selectedClaimTotalVATEUR, setSelectedClaimTotalVATEUR] = useState(0);

  // Handle selection changes to compute totals
  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
    let count = newSelection.length;
    let totalNet = 0;
    let totalVATLocal = 0;
    let totalVATEUR = 0;

    newSelection.forEach((claimId) => {
      const selectedClaim = tableData.find((claim) => claim.claimId === claimId);
      if (selectedClaim) {
        totalNet += selectedClaim.claimGrossAmount;
        totalVATLocal += selectedClaim.claimVATAmountLocCur;
        totalVATEUR += selectedClaim.claimVATAmountEUR;
      }
    });

    setSelectedClaimCount(count);
    setSelectedClaimTotalNetAmount(totalNet);
    setSelectedClaimTotalVATLocal(totalVATLocal);
    setSelectedClaimTotalVATEUR(totalVATEUR);
  };

  // **New: BulkEditFooter Component**
  const BulkEditFooter = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: "1px solid #ccc",
          paddingTop: 10,
        }}
      >
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total Selected Claims:</div>
            <div style={{ marginBottom: 5 }}>{selectedClaimCount}</div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total Net Amount:</div>
            <div style={{ marginBottom: 5 }}>
              {selectedClaimTotalNetAmount.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total VAT Local:</div>
            <div style={{ marginBottom: 5 }}>
              {selectedClaimTotalVATLocal.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total VAT EUR:</div>
            <div style={{ marginBottom: 5 }}>
              {selectedClaimTotalVATEUR.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
        </div>
        {/* **Removed: Bulk Action Buttons** */}
        <div>
          <GridFooter
            style={{ borderTop: "none" }}
            pageSize={15} // Adjust if you have dynamic page sizes
            onPageSizeChange={() => {}}
            rowsPerPageOptions={[15, 50, 100]}
          />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Helmet title="Claims" />
      <Divider my={6} />
      <Card mb={6}>
        <AddClaimForm customerId={customerId} customerNumber={customerNumber} refreshTable={fetchClaimsByEntityId} />
        <Notification notify={notify} setNotify={setNotify} />
        <Divider />
        <Grid container spacing={2} mt={0}>
          <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
          <ViewClaimDialog
            viewClaimDialog={viewClaimDialog}
            setViewClaimDialog={setViewClaimDialog}
            refreshTable={fetchClaimsByEntityId}
          />
          {/* Filter Section */}
          <Grid container spacing={2} alignItems="center" sx={{ mt: 1, mb: 3, ml: 1, pr: 2 }}>
            <Grid item xs={2}>
              <SelectWrapper
                name="countryId"
                label="Country"
                options={[{ key: "any", value: "Any" }, ...countryData.map((c) => ({ key: c.countryId, value: c.countryCode }))]}
                value={selectedCountry}
                handleChange={handleCountryValue}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectWrapper
                name="year"
                label="Year"
                options={[
                  { key: "any", value: "Any" },
                  ...Array.from({ length: 5 }, (_, i) => {
                    const year = new Date().getFullYear() - i;
                    return { key: year, value: year };
                  }),
                ]}
                value={selectedYear}
                handleChange={handleYearValue}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectWrapper
                name="startMonth"
                label="Start Month"
                options={[{ key: "any", value: "Any" }, ...monthArr.map((m) => ({ key: m.monthId, value: m.monthText }))]}
                value={selectedStartMonth}
                handleChange={handleStartMonthValue}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectWrapper
                name="endMonth"
                label="End Month"
                options={[{ key: "any", value: "Any" }, ...monthArr.map((m) => ({ key: m.monthId, value: m.monthText }))]}
                value={selectedEndMonth}
                handleChange={handleEndMonthValue}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectWrapper
                name="claimStateId"
                label="Claim State"
                options={[
                  { key: "any", value: "Any" },
                  ...claimStateData.map((state) => ({ key: state.claimStateId, value: state.description })),
                ]}
                value={selectedClaimState}
                handleChange={handleClaimStateValue}
              />
            </Grid>
            <Grid item xs={2}>
              <Button onClick={fetchClaimsByEntityId} variant="contained" sx={{ mt: 4 }}>
                Filter
              </Button>
              <Button
                onClick={() => {
                  setSelectedCountry("any");
                  setSelectedYear("any");
                  setSelectedStartMonth("any");
                  setSelectedEndMonth("any");
                  setSelectedClaimState("any");
                  fetchClaimsByEntityId();
                }}
                variant="outlined"
                sx={{ mt: 4, ml: 2 }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ColorLegend />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DataGridExport tableData={tableData} columns={columns} />
            </div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "600px",
                }}
              >
                <Typography sx={{ mr: 6, fontSize: 20 }}>Loading</Typography>
                <CircularProgress />
              </div>
            ) : (
              countryData.length > 0 && (
                <DataGrid
                  getRowId={(row) => row.claimId}
                  getRowClassName={getRowClassName}
                  checkboxSelection
                  disableSelectionOnClick
                  disableColumnSelector
                  components={{
                    Toolbar: GridToolbar,
                    Footer: BulkEditFooter, // **Integrate Custom Footer**
                  }}
                  selectionModel={selectedRows}
                  onSelectionModelChange={handleSelectionChange}
                  density="compact"
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: false },
                      printOptions: { disableToolbarButton: false },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  rows={tableData}
                  columns={columns}
                  pageSize={15}
                  pagination
                  paginationMode="client"
                  rowsPerPageOptions={[15, 50, 100]}
                  sx={{
                    height: 667,
                    width: "100%",
                    borderRadius: 1,
                    backgroundColor: "background.paper",
                    boxShadow: 2,
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main",
                    },
                    marginTop: 0,
                  }}
                />
              )
            )}
            {/* **Removed: Bulk Action Buttons** */}
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default CustomerClaims;
