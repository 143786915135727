import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Grid,
  Paper,
  Checkbox,
  TextField,
  Divider,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
// import { spacing } from "@mui/system";
// import { id } from "date-fns/locale";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const sortByName = (array) => {
  return array.slice().sort((a, b) => a.name.localeCompare(b.name));
};

const sortByNameWithDefaultOnTop = (array, defaultId) => {
  return array.slice().sort((a, b) => {
    if (a.entityId === defaultId) return -1;
    if (b.entityId === defaultId) return 1;
    return a.name.localeCompare(b.name);
  });
};

export default function ViewAccountManagerDialog(props) {
  const { viewAccountManagerDialog, setViewAccountManagerDialog, onSuccess } = props;
  const [accountManagerLinks, setAccountManagerLinks] = useState([]);
  const [entities, setEntities] = useState([]);
  const [originalEntities, setOriginalEntities] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [checked, setChecked] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEntities, setFilteredEntities] = useState([]);
  const [formValues, setFormValues] = useState({
    entityId: "",
    firstname: "",
    lastname: "",
    email: "",
  });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [newDefaultEntityId, setNewDefaultEntityId] = useState(null);
  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
  });

  useEffect(() => {
    if (viewAccountManagerDialog.isOpen) {
      axios
        .get(`/api/v1/AccountManagerLinks/GetAccountManagerLinksById/${viewAccountManagerDialog.accountManagerId}`)
        .then((res) => {
          setAccountManagerLinks(res.data);
        })
        .catch((err) => console.error(err));

      axios
        .get("/api/v1/entities")
        .then((res) => {
          const filteredEntities = res.data.filter((entity) => entity.typeRelation === 1);
          setOriginalEntities(filteredEntities);
        })
        .catch((err) => console.error(err));

      setFormValues({
        entityId: viewAccountManagerDialog.accountManagerInfo.entityId || "",
        firstname: viewAccountManagerDialog.accountManagerInfo.firstname || "",
        lastname: viewAccountManagerDialog.accountManagerInfo.lastname || "",
        email: viewAccountManagerDialog.accountManagerInfo.email || "",
      });
    }
  }, [viewAccountManagerDialog.isOpen, viewAccountManagerDialog.accountManagerId, viewAccountManagerDialog.accountManagerInfo]);

  useEffect(() => {
    if (accountManagerLinks.length > 0 && originalEntities.length > 0) {
      const selected = accountManagerLinks.map((link) => ({
        ...link,
        name: originalEntities.find((e) => e.entityId === link.entityId)?.name,
      }));
      setSelectedCompanies(selected.filter(Boolean));

      const remainingEntities = originalEntities.filter(
        (entity) => !accountManagerLinks.some((link) => link.entityId === entity.entityId) && entity.entityId !== formValues.entityId
      );
      setEntities(sortByName(remainingEntities));
      setFilteredEntities(sortByName(remainingEntities)); // Set filteredEntities initially
    } else if (originalEntities.length > 0) {
      const remainingEntities = originalEntities.filter((entity) => entity.entityId !== formValues.entityId);
      setEntities(sortByName(remainingEntities));
      setFilteredEntities(sortByName(remainingEntities)); // Set filteredEntities when selectedCompanies is empty
    }
  }, [accountManagerLinks, originalEntities, formValues.entityId]);

  useEffect(() => {
    if (formValues.entityId) {
      const entityToKeep = originalEntities.find((entity) => entity.entityId === formValues.entityId);
      if (entityToKeep) {
        setSelectedCompanies((prevSelectedCompanies) => {
          if (!prevSelectedCompanies.some((company) => company.entityId === formValues.entityId)) {
            return sortByNameWithDefaultOnTop([...prevSelectedCompanies, entityToKeep], formValues.entityId);
          }
          return sortByNameWithDefaultOnTop(prevSelectedCompanies, formValues.entityId);
        });
        setEntities((prevEntities) => prevEntities.filter((entity) => entity.entityId !== formValues.entityId));
        setFilteredEntities((prevEntities) => prevEntities.filter((entity) => entity.entityId !== formValues.entityId)); // Update filteredEntities
      }
    }
  }, [formValues.entityId, originalEntities]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    const newSelectedCompanies = sortByNameWithDefaultOnTop(selectedCompanies.concat(entities), formValues.entityId);
    setSelectedCompanies(newSelectedCompanies);
    setEntities([]);
    setFilteredEntities([]);
  };

  const handleCheckedRight = () => {
    const newSelectedCompanies = sortByNameWithDefaultOnTop(selectedCompanies.concat(leftChecked), formValues.entityId);
    const newEntities = sortByName(not(entities, leftChecked));
    setSelectedCompanies(newSelectedCompanies);
    setEntities(newEntities);
    setFilteredEntities(newEntities); // Update filteredEntities
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    const newEntities = sortByName(entities.concat(rightChecked));
    const newSelectedCompanies = sortByNameWithDefaultOnTop(not(selectedCompanies, rightChecked), formValues.entityId);
    setEntities(newEntities);
    setFilteredEntities(newEntities); // Update filteredEntities
    setSelectedCompanies(newSelectedCompanies);
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    const defaultEntity = selectedCompanies.find((company) => company.entityId === formValues.entityId);
    const remainingSelectedCompanies = selectedCompanies.filter((company) => company.entityId !== formValues.entityId);
    const newEntities = sortByName(entities.concat(remainingSelectedCompanies));
    setEntities(newEntities);
    setFilteredEntities(newEntities); // Update filteredEntities
    setSelectedCompanies(defaultEntity ? [defaultEntity] : []);
  };

  const handleSave = async () => {
    const accountManagerInfo = {
      id: viewAccountManagerDialog.accountManagerId,
      entityId: formValues.entityId,
      firstname: formValues.firstname,
      lastname: formValues.lastname,
      mail: formValues.email,
    };

    const selectedIds = selectedCompanies.map((company) => ({
      AspNetUserId: viewAccountManagerDialog.accountManagerId,
      EntityId: company.entityId,
      CreationDate: new Date().toISOString(), // Assuming the creation date is now
      CreatedBy: 1, // Replace with the appropriate user ID
    }));

    let validationErrors = {};
    if (!accountManagerInfo.firstname) validationErrors.firstname = "First Name is required";
    if (!accountManagerInfo.lastname) validationErrors.lastname = "Last Name is required";
    if (!accountManagerInfo.mail) validationErrors.email = "Email is required";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Clear errors if validation passes
    setErrors({
      firstname: "",
      lastname: "",
      email: "",
    });

    // Print the arrays to the terminal log
    console.log("Account Manager Info:", accountManagerInfo);
    console.log("Selected Companies:", selectedIds);

    try {
      await axios.put(`/api/v1/UpdateAccountManager/${viewAccountManagerDialog.accountManagerId}`, accountManagerInfo, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      await axios.put(
        `/api/v1/AccountManagerLinks/UpdateAccountManagerLinksById/${viewAccountManagerDialog.accountManagerId}`,
        selectedIds,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      handleClose();
    } catch (err) {
      console.error(err);
    } finally {
      if (onSuccess) onSuccess();
    }
  };

  const handleClose = () => {
    setViewAccountManagerDialog({ ...viewAccountManagerDialog, isOpen: false });
    setAccountManagerLinks([]);
    setEntities([]);
    setSelectedCompanies([]);
    setChecked([]);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    if (name === "entityId" && formValues.entityId) {
      setNewDefaultEntityId(value);
      setConfirmDialogOpen(true);
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    setFilteredEntities(entities.filter((entity) => entity.name.toLowerCase().includes(searchTerm.toLowerCase())));
  }, [searchTerm, entities]);

  const leftChecked = intersection(checked, entities);
  const rightChecked = intersection(checked, selectedCompanies);

  const customList = (items, isRightList = false) => (
    <Paper sx={{ overflow: "auto", height: 400, border: 1, borderColor: "divider" }}>
      <List dense component="div" role="list">
        {items.map((item, index) => {
          const labelId = `transfer-list-item-${item.entityId}-label`;
          const isEntityId = item.entityId === formValues.entityId;
          return (
            <React.Fragment key={item.entityId}>
              <ListItemButton role="listitem" onClick={isEntityId ? null : handleToggle(item)} disabled={isEntityId}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(item) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={item.name} />
              </ListItemButton>
              {isRightList && index === 0 && items.length > 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </List>
    </Paper>
  );

  // Usage for the right list
  <Paper sx={{ height: 400, overflow: "auto", mt: 2, mr: 2 }}>{customList(selectedCompanies, true)}</Paper>;

  const handleConfirmDialogClose = (removePrevious) => {
    if (removePrevious) {
      const previousDefaultCompany = selectedCompanies.find((company) => company.entityId === formValues.entityId);
      setSelectedCompanies((prevSelectedCompanies) =>
        prevSelectedCompanies.filter((company) => company.entityId !== formValues.entityId)
      );
      setEntities((prevEntities) => sortByName([...prevEntities, previousDefaultCompany]));
      setFilteredEntities((prevEntities) => sortByName([...prevEntities, previousDefaultCompany])); // Update filteredEntities
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      entityId: newDefaultEntityId,
    }));
    setConfirmDialogOpen(false);
    setNewDefaultEntityId(null);
  };

  const ConfirmationDialog = ({ open, onClose }) => (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>Change default company</DialogTitle>
      <DialogContent>
        <Typography>Do you want to remove the previously selected company from the selected company list?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          No
        </Button>
        <Button onClick={() => onClose(true)} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Dialog open={viewAccountManagerDialog.isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle id="alert-dialog-title">{viewAccountManagerDialog.title}</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" style={{ color: "black" }}>
              Default Company
            </Typography>
            <Select
              fullWidth
              label="Default Company"
              name="entityId"
              value={formValues.entityId}
              onChange={handleFormChange}
              variant="outlined"
              margin="normal"
            >
              {originalEntities.map((entity) => (
                <MenuItem key={entity.entityId} value={entity.entityId}>
                  {entity.name}
                </MenuItem>
              ))}
            </Select>
            <Box mt={3} mb={2}>
              <Divider />
            </Box>
            <Typography variant="subtitle1" style={{ color: "black" }}>
              Account Manager Info
            </Typography>
            <TextField
              fullWidth
              label="First Name"
              name="firstname"
              value={formValues.firstname}
              onChange={handleFormChange}
              variant="outlined"
              margin="normal"
              error={!!errors.firstname}
              helperText={errors.firstname}
            />
            <TextField
              fullWidth
              label="Last Name"
              name="lastname"
              value={formValues.lastname}
              onChange={handleFormChange}
              variant="outlined"
              margin="normal"
              error={!!errors.lastname}
              helperText={errors.lastname}
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formValues.email}
              onChange={handleFormChange}
              variant="outlined"
              margin="normal"
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item>
            <Box sx={{ height: "100%" }}>
              <Divider orientation="vertical" flexItem sx={{ marginLeft: "10px", height: "500px" }} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} sx={{ ml: 4 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ color: "black" }}>
                Available Companies
              </Typography>
              <TextField
                sx={{ width: "41%" }}
                label="Search Entities"
                value={searchTerm}
                onChange={handleSearchChange}
                variant="outlined"
              />
            </Grid>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={5}>
                <Paper sx={{ overflow: "auto", mt: 2, mb: 2 }}>{customList(filteredEntities)}</Paper>
              </Grid>
              <Grid item xs={2} container direction="column" justifyContent="center" alignItems="center">
                <Button
                  sx={{ my: 0.5, mt: 35 }}
                  variant="outlined"
                  size="small"
                  onClick={handleAllRight}
                  disabled={entities.length === 0}
                  aria-label="move all right"
                >
                  ≫
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleAllLeft}
                  disabled={selectedCompanies.length === 0}
                  aria-label="move all left"
                >
                  ≪
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Paper sx={{ height: 400, overflow: "auto", mt: 2, mr: 2 }}>{customList(selectedCompanies, true)}</Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Box mt={2} display="flex" justifyContent="flex-start">
          <Box mr={2}>
            <Button type="submit" variant="contained" color="primary" onClick={handleSave}>
              Update Portal Login
            </Button>
          </Box>
          <Button type="button" variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </DialogContent>
      <ConfirmationDialog open={confirmDialogOpen} onClose={handleConfirmDialogClose} />
    </Dialog>
  );
}
