import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar, GridFooter } from "@mui/x-data-grid";
import AddExciseClaimForm from "../../components/AddExciseClaimForm";
import { Grid, Box, Card as MuiCard, Typography, Divider, IconButton, CircularProgress, Button, Alert, Collapse } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import axios from "axios";
import ViewExciseClaimDialog from "../../components/ViewExciseClaimDialog";
import DataGridExport from "../../components/DataGridExport";
import SelectWrapper from "../../components/SelectBoxDataGridCell";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const Card = styled(MuiCard)(spacing);

const CustomerExciseClaims = (props) => {
  const { customerId, customerNumber } = props;
  const [loading, setLoading] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [countryData, setCountryData] = useState([]);
  // const [entityData, setEntityData] = useState([]);
  const [claimStateData, setClaimStateData] = useState([]);

  // Filter states
  const [selectedCountry, setSelectedCountry] = useState("any");
  const [selectedYear, setSelectedYear] = useState("any");
  const [selectedStartMonth, setSelectedStartMonth] = useState("any");
  const [selectedEndMonth, setSelectedEndMonth] = useState("any");
  const [selectedClaimState, setSelectedClaimState] = useState("any");
  const [isPreFinancedDisabled, setIsPreFinancedDisabled] = useState(false);

  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");
  const [customerPreFinanceData, setCustomerPreFinanceData] = useState([]);

  useEffect(() => {
    axios.get("/api/v1/preFinanceByEntityId/" + customerId).then((response) => setCustomerPreFinanceData(response.data));
  }, [customerId]);

  // Handle filter changes
  const handleCountryValue = (value) => {
    setSelectedCountry(value);
  };
  const handleYearValue = (value) => {
    setSelectedYear(value);
  };
  const handleStartMonthValue = (value) => {
    setSelectedStartMonth(value);
  };
  const handleEndMonthValue = (value) => {
    setSelectedEndMonth(value);
  };
  const handleClaimStateValue = (value) => {
    setSelectedClaimState(value);
  };

  // State for Add Excise Claim Form
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);

  // Fetch Excise Claims
  const fetchExciseClaimsByEntityId = useCallback(() => {
    setLoading(true);
    axios
      .get(`/api/v1/exciseClaimsByEntityId/${customerId}`)
      .then((response) => {
        setRawData(response.data);
        setTableData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching excise claims by entity ID:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [customerId]);

  useEffect(() => {
    fetchExciseClaimsByEntityId();
  }, [fetchExciseClaimsByEntityId]);

  // Fetch filter data (countries, entities, claim states)
  useEffect(() => {
    async function fetchData() {
      try {
        const [countries, claimStates] = await Promise.all([
          axios.get("/api/v1/countries"),
          // axios.get("/api/v1/entities"),
          axios.get("/api/v1/claimStates"),
        ]);
        setCountryData(countries.data);
        // setEntityData(entities.data);
        setClaimStateData(claimStates.data);
      } catch (error) {
        console.error("Error fetching filter data:", error);
      }
    }
    fetchData();
  }, []);

  // Apply filters
  const applyFilters = useCallback(() => {
    let filteredClaims = rawData;

    if (selectedCountry !== "any") {
      filteredClaims = filteredClaims.filter((claim) => claim.countryId === parseInt(selectedCountry));
    }
    if (selectedYear !== "any") {
      filteredClaims = filteredClaims.filter((claim) => parseInt(claim.year) === parseInt(selectedYear));
    }
    if (selectedStartMonth !== "any") {
      filteredClaims = filteredClaims.filter((claim) => parseInt(claim.startMonth) === parseInt(selectedStartMonth));
    }
    if (selectedEndMonth !== "any") {
      filteredClaims = filteredClaims.filter((claim) => parseInt(claim.endMonth) === parseInt(selectedEndMonth));
    }
    if (selectedClaimState !== "any") {
      filteredClaims = filteredClaims.filter((claim) => parseInt(claim.claimStateId) === parseInt(selectedClaimState));
    }

    setTableData(filteredClaims);
  }, [rawData, selectedCountry, selectedYear, selectedStartMonth, selectedEndMonth, selectedClaimState]);

  useEffect(() => {
    applyFilters();
  }, [selectedCountry, selectedYear, selectedStartMonth, selectedEndMonth, selectedClaimState, applyFilters]);

  const handleFilter = () => {
    applyFilters();
  };

  const handleResetFilters = () => {
    setSelectedCountry("any");
    setSelectedYear("any");
    setSelectedStartMonth("any");
    setSelectedEndMonth("any");
    setSelectedClaimState("any");
    setTableData(rawData);
  };

  // Define columns
  const columns = [
    {
      field: "claimId",
      headerName: "Claim ID",
      width: 50,
      hide: true,
    },
    {
      field: "claimCode",
      headerName: "Claim Code",
      flex: 0.8,
    },
    {
      field: "year",
      headerName: "Year",
      flex: 0.25,
    },
    {
      field: "startMonth",
      headerName: "Start Month",
      flex: 0.4,
      valueFormatter: (params) => params.value.toString().padStart(2, "0"),
    },
    {
      field: "endMonth",
      headerName: "End Month",
      flex: 0.4,
      valueFormatter: (params) => params.value.toString().padStart(2, "0"),
    },
    {
      field: "countryId",
      headerName: "Country",
      flex: 0.4,
      valueGetter: (params) => {
        const country = countryData.find((country) => country.countryId === params.row.countryId);
        return country ? country.countryCode : "";
      },
    },
    {
      field: "liters",
      headerName: "Liters",
      flex: 0.35,
      align: "right",
      valueFormatter: (params) => {
        return params.value.toFixed(2); // Display number with two decimals
      },
    },
    {
      field: "claimAmountLocal",
      headerName: "Amount Local",
      flex: 0.4,
      align: "right",
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "15px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "claimAmountEUR",
      headerName: "Amount EUR",
      flex: 0.45,
      align: "right",
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "15px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "preFinanceId",
      headerName: "Pre-Financed",
      flex: 0.4,
      renderCell: (params) => (params.value > 0 ? <CheckCircleOutlineIcon style={{ color: "green", margin: "0 auto" }} /> : ""),
    },
    {
      headerName: "Pre-Financed Date",
      flex: 0.55,
      valueGetter: (params) => {
        console.log(params.row);
        if (params.row.preFinanceId > 0) {
          const found = customerPreFinanceData.find((item) => item.preFinanceId === params.row.preFinanceId);
          if (found && found.payedOut === 1) {
            return found ? moment(found.payoutDate).format("DD-MM-YYYY") : "";
          } else {
            return "Not Finalized";
          }
        } else {
          return "";
        }
        // return params.row.preFinanceId + " " + params.row.preFinanceId;
      },
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "15px" }}>{params.value}</div>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Claim",
                  subTitle: `Are you sure you want to delete ${params.row.claimCode}?`,
                  onConfirm: () => {
                    onDeleteConfirmed(e, params.row);
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewExciseClaimDialog({
                  isOpen: true,
                  title: "View Excise Claim",
                  params: params.row,
                  countryData: countryData,
                  downloadFile: true,
                  custInfo: [
                    {
                      customerId: customerId,
                      customerNumber: customerNumber,
                    },
                  ],
                  subTitle: `View details for ${params.row.claimCode}`,
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  // Notification and Dialog states
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [viewExciseClaimDialog, setViewExciseClaimDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    custInfo: [],
  });

  // Handle Edit Confirmation
  function onEditConfirmed(e, params) {
    window.location.reload(false);
    setViewExciseClaimDialog({ ...viewExciseClaimDialog, isOpen: false });
  }

  // Handle Delete Confirmation
  function onDeleteConfirmed(e, params) {
    axios
      .delete(`/api/v1/exciseClaims/${params.claimId}`)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: "Claim deleted successfully",
          type: "success",
        });
        fetchExciseClaimsByEntityId();
      })
      .catch((error) => {
        console.log(error);
        setNotify({
          isOpen: true,
          message: "Error deleting claim",
          type: "error",
        });
      })
      .finally(() => {
        setConfirmDialog({ ...confirmDialog, isOpen: false });
      });
  }

  // Row class names based on status
  const getRowClassName = (params) => {
    const { claimSequenceNumber, uploadStatus } = params.row;
    if (claimSequenceNumber > 0) {
      if (uploadStatus === "acknowledgement") {
        return "pastelGreen";
      } else if (uploadStatus !== "" && uploadStatus !== "acknowledgement") {
        return "pastelRed";
      } else if (uploadStatus === "") {
        return "pastelBlue";
      }
    }
    return "";
  };

  // Dynamic styles for row colors
  function addDynamicStyles(rules) {
    const styleEl = document.createElement("style");
    document.head.appendChild(styleEl);
    const styleSheet = styleEl.sheet;

    rules.forEach((rule) => {
      styleSheet.insertRule(rule, styleSheet.cssRules.length);
    });
  }

  useEffect(() => {
    addDynamicStyles([
      `.pastelGreen { background-color: #b2f2bb; }`,
      `.pastelRed { background-color: #ffc9c9; }`,
      `.pastelBlue { background-color: #a5d8ff; }`,
    ]);
  }, []);

  // Color Legend Component
  const ColorLegend = () => (
    <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2} mb={2}>
      <Box display="flex" alignItems="center" mr={2}>
        <Box width={15} height={15} bgcolor="#b2f2bb" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          Claim upload status OK
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mr={2}>
        <Box width={15} height={15} bgcolor="#a5d8ff" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          Claim upload in progress
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box width={15} height={15} bgcolor="#ffc9c9" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          Error while uploading claim
        </Typography>
      </Box>
    </Box>
  );

  // **State for selected rows and totals**
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedClaimCount, setSelectedClaimCount] = useState(0);
  const [selectedClaimTotalLiters, setSelectedClaimTotalLiters] = useState(0);
  const [selectedClaimTotalAmountLocal, setSelectedClaimTotalAmountLocal] = useState(0);
  const [selectedClaimTotalAmountEUR, setSelectedClaimTotalAmountEUR] = useState(0);

  // Handle selection changes to compute totals
  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
    let count = newSelection.length;
    let totalLiters = 0;
    let totalAmountLocal = 0;
    let totalAmountEUR = 0;

    newSelection.forEach((claimId) => {
      const selectedClaim = tableData.find((claim) => claim.claimId === claimId);
      if (selectedClaim) {
        totalLiters += selectedClaim.liters;
        totalAmountLocal += selectedClaim.claimAmountLocal;
        totalAmountEUR += selectedClaim.claimAmountEUR; // Adjust if claimAmountEUR represents VAT EUR
      }
    });

    setSelectedClaimCount(count);
    setSelectedClaimTotalLiters(totalLiters);
    setSelectedClaimTotalAmountLocal(totalAmountLocal);
    setSelectedClaimTotalAmountEUR(totalAmountEUR);

    // Disable the pre-finance button if any selected row is already pre-financed.
    const disable = newSelection.some((claimId) => {
      const selectedClaim = tableData.find((claim) => claim.claimId === claimId);
      return selectedClaim && selectedClaim.preFinanceId > 0;
    });
    setIsPreFinancedDisabled(disable);
  };

  const handlePreFinanced = () => {
    if (selectedRows.length <= 0) {
      setOpenAlert(true);
      setAlertSeverity("error");
      setAlertMessage("No Excise claims selected");
      setTimeout(() => {
        setOpenAlert(false);
      }, 5000);
      return;
    }

    setConfirmDialog({
      isOpen: true,
      title: "Pre-finance Excise Claims",
      subTitle: "Are you sure you want to pre-finance selected claims?",
      onConfirm: async () => {
        try {
          for (const claimId of selectedRows) {
            // Create a new PreFinance record for each claim
            const selectedClaim = tableData.find((claim) => claim.claimId === claimId);
            let payoutAmount = selectedClaim ? selectedClaim.claimAmountLocal : 0;

            let newPreFinance = {
              entityId: customerId,
              creationDate: moment().format("YYYY-MM-DDThh:mm:ss.SSS") + "Z",
              payoutAmount: payoutAmount,
              payedOut: 0,
              preFinanceType: 1,
            };
            console.log(newPreFinance);
            await axios
              .post("/api/v1/initialCreatePreFinance", newPreFinance)
              .then((response) => {
                if (response.status !== 201) {
                  // Replace 201 with the actual status code your API returns upon successful creation
                  throw new Error("Failed to create preFinance");
                }
                const returnedId = response.data.preFinanceId;

                console.log(response);

                const jsonRowsToBePreFinanced = JSON.stringify([{ claimId: selectedClaim.claimId, preFinanceId: returnedId }]);

                console.log(jsonRowsToBePreFinanced);

                // Now use this ID in your PUT request
                return axios.put("/api/v1/exciseClaims/updateExciseClaimPreFinance", jsonRowsToBePreFinanced, {
                  headers: {
                    "Content-Type": "application/json",
                  },
                });
              })
              .then((response) => {
                if (response.status !== 204) {
                  // Replace 200 with the actual status code your API returns upon successful update
                  throw new Error("Failed to update invoicePreFinance");
                }
                console.log(response);
                setNotify({
                  isOpen: true,
                  message: "Selected claims pre-financed successfully",
                  type: "success",
                });
                fetchExciseClaimsByEntityId();
              })
              .catch((error) => {
                console.log(error);
              });
            // Optionally, you might want to update the individual claim with additional info
            // about the pre-financed status if required by your business logic.
          }
        } catch (error) {
          console.log(error);
          setNotify({
            isOpen: true,
            message: "Error pre-financing claims",
            type: "error",
          });
        } finally {
          setConfirmDialog({ ...confirmDialog, isOpen: false });
        }
      },
    });

    // Reset selected rows and totals
    setSelectedRows([]);
    setSelectedClaimCount(0);
    setSelectedClaimTotalLiters(0);
    setSelectedClaimTotalAmountLocal(0);
    setSelectedClaimTotalAmountEUR(0);
  };

  // **BulkEditFooter Component Defined Inside**
  const BulkEditFooter = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: "1px solid #ccc",
          paddingTop: 10,
          paddingLeft: 10,
        }}
      >
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total Selected Claims:</div>
            <div style={{ marginBottom: 5 }}>{selectedClaimCount}</div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total Liters:</div>
            <div style={{ marginBottom: 5 }}>
              {selectedClaimTotalLiters.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total Amount Local:</div>
            <div style={{ marginBottom: 5 }}>
              {selectedClaimTotalAmountLocal.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total Amount EUR:</div>
            <div style={{ marginBottom: 5 }}>
              {selectedClaimTotalAmountEUR.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
        </div>
        {/* **Removed: Bulk Action Buttons** */}
        <div>
          <GridFooter
            style={{ borderTop: "none" }}
            pageSize={15} // Adjust if you have dynamic page sizes
            onPageSizeChange={() => {}}
            rowsPerPageOptions={[15, 50, 100]}
          />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Helmet title="Excise Claims" />
      <Box sx={{ width: "100%" }}>
        <Collapse in={openAlert}>
          <Alert
            severity={alertSeverity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {alertMessage}
          </Alert>
        </Collapse>
      </Box>
      <Divider my={6} />
      <Card mb={6}>
        <Button variant="contained" color="primary" sx={{ m: 6, mt: 3, mb: 3, ml: 4 }} onClick={() => setIsAddFormOpen(true)}>
          Add Excise Claim
        </Button>
        <AddExciseClaimForm
          customerId={customerId}
          customerNumber={customerNumber}
          refreshGrid={fetchExciseClaimsByEntityId}
          isOpen={isAddFormOpen}
          onClose={() => setIsAddFormOpen(false)}
        />
        <Notification notify={notify} setNotify={setNotify} />

        <Divider />
        {/* Filter Section */}
        <Grid container spacing={2} alignItems="center" sx={{ mt: 1, mb: 3, ml: 1, pr: 2 }}>
          <Grid item xs={2}>
            <SelectWrapper
              name="countryId"
              label="Country"
              options={[{ key: "any", value: "Any" }, ...countryData.map((c) => ({ key: c.countryId, value: c.countryCode }))]}
              value={selectedCountry}
              handleChange={handleCountryValue}
            />
          </Grid>
          <Grid item xs={2}>
            <SelectWrapper
              name="year"
              label="Year"
              options={[
                { key: "any", value: "Any" },
                ...Array.from({ length: 5 }, (_, i) => {
                  const year = new Date().getFullYear() - i;
                  return { key: year, value: year.toString() };
                }),
              ]}
              value={selectedYear}
              handleChange={handleYearValue}
            />
          </Grid>
          <Grid item xs={2}>
            <SelectWrapper
              name="startMonth"
              label="Start Month"
              options={[
                { key: "any", value: "Any" },
                ...Array.from({ length: 12 }, (_, i) => ({
                  key: i + 1,
                  value: (i + 1).toString().padStart(2, "0"),
                })),
              ]}
              value={selectedStartMonth}
              handleChange={handleStartMonthValue}
            />
          </Grid>
          <Grid item xs={2}>
            <SelectWrapper
              name="endMonth"
              label="End Month"
              options={[
                { key: "any", value: "Any" },
                ...Array.from({ length: 12 }, (_, i) => ({
                  key: i + 1,
                  value: (i + 1).toString().padStart(2, "0"),
                })),
              ]}
              value={selectedEndMonth}
              handleChange={handleEndMonthValue}
            />
          </Grid>
          <Grid item xs={2}>
            <SelectWrapper
              name="claimStateId"
              label="Claim State"
              options={[
                { key: "any", value: "Any" },
                ...claimStateData.map((state) => ({
                  key: state.claimStateId,
                  value: state.description,
                })),
              ]}
              value={selectedClaimState}
              handleChange={handleClaimStateValue}
            />
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Button variant="contained" sx={{ mr: 2 }} onClick={handleFilter}>
                Filter
              </Button>
              <Button variant="outlined" onClick={handleResetFilters}>
                Reset
              </Button>
            </Box>
          </Grid>
        </Grid>
        {/* Color Legend */}
        <ColorLegend />
        {/* DataGrid Section */}
        <Grid container spacing={2} mt={0}>
          <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
          <ViewExciseClaimDialog
            viewExciseClaimDialog={viewExciseClaimDialog}
            setViewExciseClaimDialog={setViewExciseClaimDialog}
            refreshGrid={fetchExciseClaimsByEntityId}
          />
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" mb={2}>
              <DataGridExport tableData={tableData} columns={columns} />
            </Box>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="600px">
                <Typography sx={{ mr: 6, fontSize: 20 }}>Loading</Typography>
                <CircularProgress />
              </Box>
            ) : (
              countryData.length > 0 && (
                <DataGrid
                  rows={tableData}
                  columns={columns}
                  pageSize={15}
                  density="compact"
                  components={{
                    Toolbar: GridToolbar,
                    Footer: BulkEditFooter, // Integrate Custom Footer
                  }}
                  selectionModel={selectedRows}
                  onSelectionModelChange={handleSelectionChange}
                  checkboxSelection // Enable checkbox selection
                  getRowClassName={getRowClassName}
                  getRowId={(row) => row.claimId}
                  disableSelectionOnClick
                  disableColumnSelector
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: false },
                      printOptions: { disableToolbarButton: false },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  pagination
                  paginationMode="client"
                  rowsPerPageOptions={[15, 50, 100]}
                  sx={{
                    height: 667,
                    width: "100%",
                    borderRadius: 1,
                    backgroundColor: "background.paper",
                    boxShadow: 2,
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main",
                    },
                    marginTop: 0,
                  }}
                />
              )
            )}
            <div style={{ alignSelf: "flex-end", marginBottom: 5, marginTop: 10 }}>
              <Button variant="contained" color="primary" onClick={handlePreFinanced} disabled={isPreFinancedDisabled} sx={{ mr: 3 }}>
                Set selected to pre-financed
              </Button>
            </div>
          </Grid>
        </Grid>
      </Card>
      {/* Notification and Alerts */}
      <Box sx={{ width: "100%" }}>
        <Collapse in={notify.isOpen}>
          <Alert
            severity={notify.type}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setNotify({ ...notify, isOpen: false });
                }}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {notify.message}
          </Alert>
        </Collapse>
      </Box>
    </React.Fragment>
  );
};

export default CustomerExciseClaims;
