import React, { useEffect, useState, useCallback } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  Checkbox,
  TextField,
  Collapse,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import apiUtils from "../utils/apiUtils";

const Card = styled(MuiCard)``;
const Button = styled(MuiButton)``;

export default function ViewPreFinanceExciseDialog(props) {
  const { viewPreFinanceExciseDialog, setViewPreFinanceExciseDialog } = props;
  const [updatingData, setUpdatingData] = useState(false);
  const [payoutAmount, setPayoutAmount] = useState("");
  const [checked, setChecked] = useState(0);
  const [selectedDate, setSelectedDate] = useState("0001-01-01");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");
  const [claimCode, setClaimCode] = useState("");

  const fetchExciseData = useCallback(async () => {
    if (viewPreFinanceExciseDialog.isOpen && viewPreFinanceExciseDialog.params?.preFinanceId) {
      try {
        const response = await apiUtils.fetchData(
          `/api/v1/exciseClaimsByPreFinanceId/${viewPreFinanceExciseDialog.params.preFinanceId}`
        );
        const data = response[0] || {};
        setClaimCode(data.claimCode || "");
        // Only update payoutAmount if the API returns a valid (non-null/undefined) value.
        if (data.payoutAmount !== undefined && data.payoutAmount !== null) {
          setPayoutAmount(data.payoutAmount);
        }
        setChecked(data.payedOut === 1);
      } catch (error) {
        console.error("Error fetching excise claim data:", error);
      }
    }
  }, [viewPreFinanceExciseDialog.isOpen, viewPreFinanceExciseDialog.params?.preFinanceId]);

  useEffect(() => {
    if (viewPreFinanceExciseDialog.isOpen) {
      // Initialize state from dialog params if available.
      if (viewPreFinanceExciseDialog.params?.payoutAmount !== undefined) {
        setPayoutAmount(viewPreFinanceExciseDialog.params.payoutAmount);
      }
      setChecked(viewPreFinanceExciseDialog.params?.payedOut === 1);
      fetchExciseData();
    }
  }, [
    viewPreFinanceExciseDialog.isOpen,
    viewPreFinanceExciseDialog.params?.payoutAmount,
    viewPreFinanceExciseDialog.params?.payedOut,
    fetchExciseData,
  ]);

  const handleUpdate = async () => {
    setUpdatingData(true);
    if (!checked && selectedDate !== "0001-01-01") {
      setAlertMessage("Date cannot be set while Finalized Payout is not checked");
      setAlertSeverity("error");
      setOpenAlert(true);
      setTimeout(() => {
        setOpenAlert(false);
        setUpdatingData(false);
      }, 2000);
      return;
    }

    if (checked) {
      const selectedDateObj = new Date(selectedDate);
      const creationDateObj = new Date(viewPreFinanceExciseDialog?.params?.creationDate);
      if (isNaN(selectedDateObj.getTime()) || selectedDateObj < creationDateObj) {
        setAlertMessage("Selected date is invalid or older than the creation date");
        setAlertSeverity("error");
        setOpenAlert(true);
        setTimeout(() => {
          setOpenAlert(false);
          setUpdatingData(false);
        }, 2000);
        return;
      }
    }

    const payoutAmountSanitized = String(payoutAmount).replace(",", ".");
    const payoutAmountFloat = parseFloat(payoutAmountSanitized);
    if (isNaN(payoutAmountFloat) || payoutAmountFloat <= 0.01) {
      setAlertMessage("Payout amount should be a decimal and higher than 0.01");
      setAlertSeverity("error");
      setOpenAlert(true);
      setTimeout(() => {
        setOpenAlert(false);
      }, 5000);
      return;
    }

    let updatedPreFinance = {
      entityId: viewPreFinanceExciseDialog.params.entityId,
      creationDate: viewPreFinanceExciseDialog.params.creationDate,
      payedOut: checked ? 1 : 0,
      payoutDate: checked ? selectedDate : "0001-01-01",
      payoutAmount: payoutAmountFloat,
      preFinanceType: 1,
    };

    axios
      .put("/api/v1/preFinance/" + viewPreFinanceExciseDialog.params.preFinanceId, updatedPreFinance)
      .then((response) => {
        fetchExciseData();
      })
      .catch((error) => {
        console.log(error);
        setAlertMessage("Error updating pre-finance data");
        setAlertSeverity("error");
        setOpenAlert(true);
        setTimeout(() => {
          setOpenAlert(false);
        }, 2000);
      });

    setTimeout(() => {
      setUpdatingData(false);
      setAlertMessage("Excise data successfully updated.");
      setAlertSeverity("success");
      setOpenAlert(true);
      setTimeout(() => setOpenAlert(false), 5000);
      viewPreFinanceExciseDialog.params.payedOut = checked ? 1 : 0;
      viewPreFinanceExciseDialog.params.payoutDate = selectedDate;
      fetchExciseData();
    }, 1000);
  };

  const handleClose = () => {
    setViewPreFinanceExciseDialog({
      ...viewPreFinanceExciseDialog,
      isOpen: false,
    });
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handlePayoutAmountChange = (event) => {
    setPayoutAmount(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <>
      <Dialog open={viewPreFinanceExciseDialog.isOpen} aria-labelledby="excise-dialog-title" fullWidth maxWidth="sm">
        <DialogTitle id="excise-dialog-title">{viewPreFinanceExciseDialog.title}</DialogTitle>
        <Divider />
        <DialogContent>
          <Card>
            <CardContent>
              <Box sx={{ width: "100%" }}>
                <Collapse in={openAlert}>
                  <Alert
                    severity={alertSeverity}
                    action={
                      <IconButton aria-label="close" color="inherit" size="small" onClick={() => setOpenAlert(false)}>
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    {alertMessage}
                  </Alert>
                </Collapse>
              </Box>
              <Grid container xs={12} sx={{ mb: 5 }}>
                <Grid container xs={6} spacing={1} sx={{ pr: 2 }}>
                  <Grid item xs={6} height={30}>
                    <Typography>Excise Claim Code:</Typography>
                  </Grid>
                  <Grid item xs={6} height={30}>
                    <Typography>{claimCode}</Typography>
                  </Grid>
                  <Grid item xs={6} height={30}>
                    <Typography>Creation Date:</Typography>
                  </Grid>
                  <Grid item xs={6} height={30}>
                    <Typography>{formatDate(viewPreFinanceExciseDialog?.params?.creationDate)}</Typography>
                  </Grid>
                  <Grid item xs={6} height={30}>
                    <Typography>Payout Date:</Typography>
                  </Grid>
                  <Grid item xs={6} height={30}>
                    <Typography>
                      {viewPreFinanceExciseDialog?.params?.payedOut === 0 ? (
                        <TextField
                          id="payoutDate"
                          type="date"
                          value={selectedDate}
                          onChange={handleDateChange}
                          sx={{ "& .MuiOutlinedInput-input": { padding: 1.0 } }}
                        />
                      ) : (
                        <Typography>
                          {new Date(viewPreFinanceExciseDialog?.params?.payoutDate) <
                          new Date(viewPreFinanceExciseDialog?.params?.creationDate)
                            ? "00-00-0000"
                            : new Date(viewPreFinanceExciseDialog?.params?.payoutDate).toLocaleDateString("nl-NL", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} height={30}>
                    <Typography>Payout Amount:</Typography>
                  </Grid>
                  <Grid item xs={6} height={30}>
                    <Typography>
                      <TextField
                        id="payoutAmount"
                        disabled={viewPreFinanceExciseDialog?.params?.payedOut === 1}
                        value={payoutAmount}
                        onChange={handlePayoutAmountChange}
                        sx={{ "& .MuiOutlinedInput-input": { padding: 1.0 } }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={6} height={30}>
                    <Typography>Finalized payout:</Typography>
                  </Grid>
                  <Grid item xs={6} height={30}>
                    <Typography>
                      <Checkbox
                        id="payedOut"
                        disabled={viewPreFinanceExciseDialog?.params?.payedOut === 1}
                        checked={viewPreFinanceExciseDialog?.params?.payedOut === 1 ? true : checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{ padding: 0, margin: 0 }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-start" sx={{ marginTop: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdate}
                  disabled={updatingData}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Update Excise Data
                  {updatingData && <CircularProgress size={20} sx={{ marginLeft: 1 }} />}
                </Button>
                <Button variant="contained" color="primary" onClick={handleClose} sx={{ marginLeft: 2 }}>
                  Close
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
}
