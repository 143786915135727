// Frontend: src/api/notifications.js
import axios from "axios";

const fetchNotifications = async () => {
  try {
    const response = await axios.get("/api/v1/notifications");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const markAsRead = async (id) => {
  try {
    const response = await axios.put(`/api/v1/notifications/${id}/read`);
    return response.status === 204;
  } catch (error) {
    console.log(error);
  }
};

const markAsUnread = async (id) => {
  try {
    const response = await axios.put(`/api/v1/notifications/${id}/unread`);
    return response.status === 204;
  } catch (error) {
    console.log(error);
  }
};

const deleteNotification = async (id) => {
  try {
    const response = await axios.delete(`/api/v1/notifications/${id}`);
    return response.status === 204;
  } catch (error) {
    console.log(error);
  }
};

const markAllAsRead = async () => {
  try {
    const response = await axios.put(`/api/v1/notifications/markAllAsRead`);
    return response.status === 204;
  } catch (error) {
    console.log(error);
  }
};

// const subscribeToNotifications = (onReceive) => {
//   const connection = new (require("@microsoft/signalr").HubConnectionBuilder)()
//     .withUrl("/notificationHub")
//     .configureLogging(require("@microsoft/signalr").LogLevel.Information)
//     .withAutomaticReconnect()
//     .build();

//   connection
//     .start()
//     .then(() => {
//       connection.on("ReceiveNotification", (notification) => {
//         onReceive(notification);
//       });
//     })
//     .catch((err) => console.error("SignalR Connection Error: ", err));

//   return () => {
//     connection.stop();
//   };
// };

const notificationApi = {
  fetchNotifications,
  markAsRead,
  markAsUnread,
  deleteNotification,
  markAllAsRead,
  //   subscribeToNotifications,
};

export default notificationApi;
