// Frontend: src/components/NavbarNotificationsDropdown.js

import React, { useContext, useState } from "react";
// import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  SvgIcon,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Bell, Trash2, Check, RefreshCw } from "react-feather";
import axios from "axios";
import { GlobalContext } from "../../contexts/GlobalContext";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const AvatarStyled = styled("div")`
  background: ${(props) => props.theme.palette.primary.main};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({ title, description, Icon, isRead, onDelete, onMarkAsRead, onMarkAsUnread }) {
  return (
    <ListItem divider>
      <ListItemAvatar>
        <AvatarStyled>
          <SvgIcon fontSize="small">
            <Icon />
          </SvgIcon>
        </AvatarStyled>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={description}
      />
      {!isRead ? (
        <Tooltip title="Mark as Read">
          <IconButton onClick={onMarkAsRead}>
            <Check size={16} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Mark as Unread">
          <IconButton onClick={onMarkAsUnread}>
            <RefreshCw size={16} />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Delete">
        <IconButton edge="end" aria-label="delete" onClick={onDelete}>
          <Trash2 size={16} />
        </IconButton>
      </Tooltip>
    </ListItem>
  );
}

function NavbarNotificationsDropdown() {
  const ref = React.useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [openAll, setOpenAll] = useState(false);

  const { notifications, setNotifications, unreadCount, setUnreadCount } = useContext(GlobalContext);

  const handleOpen = async () => {
    setOpen(true);
    try {
      const response = await axios.get("/api/v1/notifications");
      if (Array.isArray(response.data)) {
        setNotifications(response.data);
        setUnreadCount(response.data.filter((n) => !n.isRead).length);
      } else if (response.data.notifications && Array.isArray(response.data.notifications)) {
        setNotifications(response.data.notifications);
        setUnreadCount(response.data.notifications.filter((n) => !n.isRead).length);
      } else {
        console.error("Unexpected notifications format:", response.data);
      }
    } catch (error) {
      console.log("Error fetching notifications:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id) => {
    try {
      await axios.put(`/api/v1/notifications/${id}/delete`);
      const notificationToDelete = notifications.find((notification) => notification.id === id);
      setNotifications(notifications.filter((notification) => notification.id !== id));
      if (notificationToDelete && !notificationToDelete.isRead) {
        setUnreadCount(unreadCount - 1);
      }
    } catch (error) {
      console.log("Error deleting notification:", error);
    }
  };

  const handleMarkAsRead = async (id) => {
    try {
      await axios.put(`/api/v1/notifications/${id}/read`);
      setNotifications(
        notifications.map((notification) => (notification.id === id ? { ...notification, isRead: true } : notification))
      );
      setUnreadCount(unreadCount - 1);
    } catch (error) {
      console.log("Error marking notification as read:", error);
    }
  };

  const handleMarkAsUnread = async (id) => {
    try {
      await axios.put(`/api/v1/notifications/${id}/unread`);
      setNotifications(
        notifications.map((notification) => (notification.id === id ? { ...notification, isRead: false } : notification))
      );
      setUnreadCount(unreadCount + 1);
    } catch (error) {
      console.log("Error marking notification as unread:", error);
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={unreadCount}>
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {unreadCount} New Notification{unreadCount !== 1 ? "s" : ""}
          </Typography>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding>
            {notifications.map((notification) => (
              <Notification
                key={notification.id}
                title={notification.title}
                description={notification.description}
                Icon={getIconComponent(notification.icon)}
                isRead={notification.isRead}
                onDelete={() => handleDelete(notification.id)}
                onMarkAsRead={() => handleMarkAsRead(notification.id)}
                onMarkAsUnread={() => handleMarkAsUnread(notification.id)}
              />
            ))}
          </List>
          <Box p={1} display="flex" justifyContent="center">
            <Button size="small" onClick={() => setOpenAll(true)}>
              Show all notifications
            </Button>
          </Box>
        </React.Fragment>
      </Popover>

      {/* Dialog for All Notifications */}
      <Dialog open={openAll} onClose={() => setOpenAll(false)} fullWidth maxWidth="sm">
        <DialogTitle>All Notifications</DialogTitle>
        <DialogContent dividers>
          <List>
            {notifications.map((notification) => (
              <Notification
                key={notification.id}
                title={notification.title}
                description={notification.description}
                Icon={getIconComponent(notification.icon)}
                isRead={notification.isRead}
                onDelete={() => handleDelete(notification.id)}
                onMarkAsRead={() => handleMarkAsRead(notification.id)}
                onMarkAsUnread={() => handleMarkAsUnread(notification.id)}
              />
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAll(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

function getIconComponent(iconName) {
  const icons = {
    Bell: Bell,
    // Add other icons if needed
  };
  return icons[iconName] || Bell;
}

export default NavbarNotificationsDropdown;
