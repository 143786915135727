import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Grid,
  Paper,
  Checkbox,
  TextField,
  Divider,
  Select,
  MenuItem,
  Typography,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import PasswordIcon from "@mui/icons-material/Password";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import { spacing } from "@mui/system";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const sortByName = (array) => {
  return array.slice().sort((a, b) => a.name.localeCompare(b.name));
};

const sortByNameWithDefaultOnTop = (array, defaultId) => {
  return array.slice().sort((a, b) => {
    if (a.entityId === defaultId) return -1;
    if (b.entityId === defaultId) return 1;
    return a.name.localeCompare(b.name);
  });
};

export default function AddAccountManagerDialog(props) {
  const { addAccountManagerDialog, setAddAccountManagerDialog, onSuccess } = props;
  const [entities, setEntities] = useState([]);
  const [originalEntities, setOriginalEntities] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [checked, setChecked] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [formValues, setFormValues] = useState({
    entityId: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "", // Added password to the form values
  });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [newDefaultEntityId, setNewDefaultEntityId] = useState(null);
  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "", // Added password to errors
  });
  const [showPassword, setShowPassword] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  // const [submitInError, setSubmitInError] = useState(false);

  useEffect(() => {
    if (addAccountManagerDialog.isOpen) {
      axios
        .get("/api/v1/entities")
        .then((res) => {
          const filteredEntities = res.data.filter((entity) => entity.typeRelation === 1);
          setOriginalEntities(filteredEntities);
          setEntities(filteredEntities);
        })
        .catch((err) => console.error(err));

      setFormValues({
        entityId: "",
        firstname: "",
        lastname: "",
        email: "",
        password: "", // Reset password field
      });
      setSelectedCompanies([]);
    }
  }, [addAccountManagerDialog.isOpen]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    const newSelectedCompanies = sortByNameWithDefaultOnTop(selectedCompanies.concat(entities), formValues.entityId);
    setSelectedCompanies(newSelectedCompanies);
    setEntities([]);
  };

  const handleCheckedRight = () => {
    const newSelectedCompanies = sortByNameWithDefaultOnTop(selectedCompanies.concat(leftChecked), formValues.entityId);
    const newEntities = sortByName(not(entities, leftChecked));
    setSelectedCompanies(newSelectedCompanies);
    setEntities(newEntities);
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    const newEntities = sortByName(entities.concat(rightChecked));
    const newSelectedCompanies = sortByNameWithDefaultOnTop(not(selectedCompanies, rightChecked), formValues.entityId);
    setEntities(newEntities);
    setSelectedCompanies(newSelectedCompanies);
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    const defaultEntity = selectedCompanies.find((company) => company.entityId === formValues.entityId);
    const remainingSelectedCompanies = selectedCompanies.filter((company) => company.entityId !== formValues.entityId);
    const newEntities = sortByName(entities.concat(remainingSelectedCompanies));
    setEntities(newEntities);
    setSelectedCompanies(defaultEntity ? [defaultEntity] : []);
  };

  const handleSave = async () => {
    const accountManagerInfo = {
      firstname: formValues.firstname,
      lastname: formValues.lastname,
      email: formValues.email,
      username: formValues.email,
      password: formValues.password,
      active: 1,
      entityId: formValues.entityId,
      isAccountManager: 1,
    };

    let validationErrors = {};
    if (!accountManagerInfo.firstname) validationErrors.firstname = "First Name is required";
    if (!accountManagerInfo.lastname) validationErrors.lastname = "Last Name is required";
    if (!accountManagerInfo.email) validationErrors.email = "Email is required";
    if (!accountManagerInfo.password) validationErrors.password = "Password is required";
    if (!accountManagerInfo.entityId) validationErrors.entityId = "Default Company is required";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      entityId: "", // Clear entityId error
    });

    console.log("Account Manager Info:", accountManagerInfo);

    try {
      const response = await axios.post("/api/v1/CreateAppUser", accountManagerInfo);
      if (response.status >= 200 && response.status < 300) {
        const createdUserId = response.data.userId; // Get the created user ID from the response

        const selectedIds = selectedCompanies.map((company) => ({
          AspNetUserId: createdUserId,
          EntityId: company.entityId,
          CreationDate: new Date().toISOString(),
          CreatedBy: 1, // Replace with the appropriate user ID
        }));

        await axios.put(`/api/v1/AccountManagerLinks/UpdateAccountManagerLinksById/${createdUserId}`, selectedIds, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        // setErrorMessage("Your data has been submitted successfully!");
        // setSubmitInError(false);
        handleClose();
      } else {
        console.error("Error adding new Account Manager.");
        // setErrorMessage("Error adding new Account Manager.");
        // setSubmitInError(true);
      }
    } catch (error) {
      console.error("Error: ", error);
      // setErrorMessage("Error: " + error.message);
      // setSubmitInError(true);
    } finally {
      if (onSuccess) onSuccess();
    }
  };

  const handleClose = () => {
    setAddAccountManagerDialog({ ...addAccountManagerDialog, isOpen: false });
    setEntities([]);
    setSelectedCompanies([]);
    setChecked([]);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    if (name === "entityId") {
      if (formValues.entityId) {
        setNewDefaultEntityId(value);
        setConfirmDialogOpen(true);
      } else {
        const selectedEntity = originalEntities.find((entity) => entity.entityId === value);
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        setSelectedCompanies((prevSelectedCompanies) => {
          if (!prevSelectedCompanies.some((company) => company.entityId === value)) {
            return sortByNameWithDefaultOnTop([...prevSelectedCompanies, selectedEntity], value);
          }
          return sortByNameWithDefaultOnTop(prevSelectedCompanies, value);
        });
        setEntities((prevEntities) => prevEntities.filter((entity) => entity.entityId !== value));
      }
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const leftChecked = intersection(checked, entities);
  const rightChecked = intersection(checked, selectedCompanies);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredEntities = entities.filter((entity) => entity.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const customList = (items, isRightList = false) => (
    <Paper sx={{ overflow: "auto", height: 400, border: 1, borderColor: "divider" }}>
      <List dense component="div" role="list">
        {items.map((item, index) => {
          const labelId = `transfer-list-item-${item.entityId}-label`;
          const isEntityId = item.entityId === formValues.entityId;
          return (
            <React.Fragment key={item.entityId}>
              <ListItemButton role="listitem" onClick={isEntityId ? null : handleToggle(item)} disabled={isEntityId}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(item) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={item.name} />
              </ListItemButton>
              {isRightList && index === 0 && items.length > 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </List>
    </Paper>
  );

  const handleConfirmDialogClose = (removePrevious) => {
    if (removePrevious) {
      const previousDefaultCompany = selectedCompanies.find((company) => company.entityId === formValues.entityId);
      setSelectedCompanies((prevSelectedCompanies) =>
        prevSelectedCompanies.filter((company) => company.entityId !== formValues.entityId)
      );
      setEntities((prevEntities) => sortByName([...prevEntities, previousDefaultCompany]));
    }
    const selectedEntity = originalEntities.find((entity) => entity.entityId === newDefaultEntityId);
    setSelectedCompanies((prevSelectedCompanies) => {
      if (!prevSelectedCompanies.some((company) => company.entityId === newDefaultEntityId)) {
        return sortByNameWithDefaultOnTop([...prevSelectedCompanies, selectedEntity], newDefaultEntityId);
      }
      return sortByNameWithDefaultOnTop(prevSelectedCompanies, newDefaultEntityId);
    });
    setEntities((prevEntities) => prevEntities.filter((entity) => entity.entityId !== newDefaultEntityId));
    setFormValues((prevValues) => ({
      ...prevValues,
      entityId: newDefaultEntityId,
    }));
    setConfirmDialogOpen(false);
    setNewDefaultEntityId(null);
  };

  const ConfirmationDialog = ({ open, onClose }) => (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>Change default company</DialogTitle>
      <DialogContent>
        <Typography>Do you want to remove the previously selected company from the selected company list?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          No
        </Button>
        <Button onClick={() => onClose(true)} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  function generatePassword() {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&*_+-=?";
    let password = "";
    for (let i = 0; i < 12; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      password += chars[randomIndex];
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      password: password,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: "", // Clear any existing password errors
    }));
  }

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      // You could set a state variable here to show a success message if desired
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  };

  return (
    <Dialog open={addAccountManagerDialog.isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle id="alert-dialog-title">{addAccountManagerDialog.title}</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" style={{ color: "black" }}>
              Default Company
            </Typography>
            <Select
              fullWidth
              label="Default Company"
              name="entityId"
              value={formValues.entityId}
              onChange={handleFormChange}
              variant="outlined"
              margin="normal"
              error={!!errors.entityId} // Show error if entityId has an error
            >
              <MenuItem value="" disabled>
                Select default company
              </MenuItem>
              {originalEntities.map((entity) => (
                <MenuItem key={entity.entityId} value={entity.entityId}>
                  {entity.name}
                </MenuItem>
              ))}
            </Select>
            {errors.entityId && (
              <Typography color="error" variant="caption">
                {errors.entityId}
              </Typography>
            )}
            <Box mt={3} mb={2}>
              <Divider />
            </Box>
            <Typography variant="subtitle1" style={{ color: "black" }}>
              Account Manager Info
            </Typography>
            <TextField
              fullWidth
              label="First Name"
              name="firstname"
              value={formValues.firstname}
              onChange={handleFormChange}
              variant="outlined"
              margin="normal"
              error={!!errors.firstname}
              helperText={errors.firstname}
            />
            <TextField
              fullWidth
              label="Last Name"
              name="lastname"
              value={formValues.lastname}
              onChange={handleFormChange}
              variant="outlined"
              margin="normal"
              error={!!errors.lastname}
              helperText={errors.lastname}
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formValues.email}
              onChange={handleFormChange}
              variant="outlined"
              margin="normal"
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              name="password"
              label="Password"
              value={formValues.password}
              error={!!errors.password}
              fullWidth
              helperText={errors.password}
              onChange={handleFormChange}
              type={showPassword ? "text" : "password"}
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                shrink: formValues.password.length > 0,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="generate password" onClick={generatePassword} edge="end">
                      <PasswordIcon />
                    </IconButton>
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    <IconButton aria-label="copy to clipboard" onClick={() => copyToClipboard(formValues.password)} edge="end">
                      <ContentCopy />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Box sx={{ height: "100%" }}>
              <Divider orientation="vertical" flexItem sx={{ marginLeft: "10px", height: "500px" }} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} sx={{ ml: 4 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ color: "black" }}>
                Available Companies
              </Typography>
              <TextField
                sx={{ width: "41%" }}
                label="Search Entities"
                value={searchTerm}
                onChange={handleSearchChange}
                variant="outlined"
              />
            </Grid>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={5}>
                <Paper sx={{ overflow: "auto", mt: 2, mb: 2 }}>{customList(filteredEntities)}</Paper>
              </Grid>
              <Grid item xs={2} container direction="column" justifyContent="center" alignItems="center">
                <Button
                  sx={{ my: 0.5, mt: 35 }}
                  variant="outlined"
                  size="small"
                  onClick={handleAllRight}
                  disabled={entities.length === 0 || !formValues.entityId}
                  aria-label="move all right"
                >
                  ≫
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0 || !formValues.entityId}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleAllLeft}
                  disabled={selectedCompanies.length === 0}
                  aria-label="move all left"
                >
                  ≪
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Paper sx={{ height: 400, overflow: "auto", mt: 2, mr: 2 }}>{customList(selectedCompanies, true)}</Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Box mt={2} display="flex" justifyContent="flex-start">
          <Box mr={2}>
            <Button type="submit" variant="contained" color="primary" onClick={handleSave}>
              Add Account Manager
            </Button>
          </Box>
          <Button type="button" variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </DialogContent>
      <ConfirmationDialog open={confirmDialogOpen} onClose={handleConfirmDialogClose} />
    </Dialog>
  );
}
