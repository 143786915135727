import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar, GridFooter } from "@mui/x-data-grid";
import { Grid, Box, Card as MuiCard, Typography, Divider, IconButton, CircularProgress, Button, Alert, Collapse } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import axios from "axios";
import ViewExciseClaimDialog from "../../components/ViewExciseClaimDialog";
import DataGridExport from "../../components/DataGridExport";
// import moment from "moment";
import SelectWrapper from "../../components/SelectBoxDataGridCell";

const Card = styled(MuiCard)(spacing);

const CustomerExciseClaims = (props) => {
  // State variables
  const [loading, setLoading] = useState(false);
  const [rawData, setRawData] = useState([]); // Raw data fetched from API
  const [tableData, setTableData] = useState([]); // Filtered data for display
  const [countryData, setCountryData] = useState([]);
  const [entityData, setEntityData] = useState([]);
  const [claimStateData, setClaimStateData] = useState([]);

  // Filter states
  const [selectedCountry, setSelectedCountry] = useState("any");
  const [selectedYear, setSelectedYear] = useState("any");
  const [selectedStartMonth, setSelectedStartMonth] = useState("any");
  const [selectedEndMonth, setSelectedEndMonth] = useState("any");
  const [selectedClaimState, setSelectedClaimState] = useState("any");

  // Handlers for filter changes
  const handleCountryValue = (value) => {
    setSelectedCountry(value);
  };
  const handleYearValue = (value) => {
    setSelectedYear(value);
  };
  const handleStartMonthValue = (value) => {
    setSelectedStartMonth(value);
  };
  const handleEndMonthValue = (value) => {
    setSelectedEndMonth(value);
  };
  const handleClaimStateValue = (value) => {
    setSelectedClaimState(value);
  };

  // State for Add Excise Claim Form (if needed in future)
  // const [isAddFormOpen, setIsAddFormOpen] = useState(false);

  // Fetch excise claims by entity ID
  const fetchExciseClaimsByEntityId = useCallback(() => {
    setLoading(true);
    axios
      .get("/api/v1/exciseClaims")
      .then((response) => {
        setRawData(response.data);
        setTableData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching claims by entity ID:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchExciseClaimsByEntityId();
  }, [fetchExciseClaimsByEntityId]);

  // Fetch filter data
  useEffect(() => {
    async function fetchData() {
      try {
        const [countries, entities, claimStates] = await Promise.all([
          axios.get("/api/v1/countries"),
          axios.get("/api/v1/entities"),
          axios.get("/api/v1/claimStates"),
        ]);
        setCountryData(countries.data);
        setEntityData(entities.data);
        setClaimStateData(claimStates.data);
      } catch (error) {
        console.error("Error fetching filter data:", error);
      }
    }
    fetchData();
  }, []);

  // Function to apply filters
  const applyFilters = useCallback(() => {
    let filteredClaims = rawData;

    if (selectedCountry !== "any") {
      filteredClaims = filteredClaims.filter((claim) => claim.countryId === parseInt(selectedCountry));
    }
    if (selectedYear !== "any") {
      filteredClaims = filteredClaims.filter((claim) => parseInt(claim.year) === parseInt(selectedYear));
    }
    if (selectedStartMonth !== "any") {
      filteredClaims = filteredClaims.filter((claim) => parseInt(claim.startMonth) === parseInt(selectedStartMonth));
    }
    if (selectedEndMonth !== "any") {
      filteredClaims = filteredClaims.filter((claim) => parseInt(claim.endMonth) === parseInt(selectedEndMonth));
    }
    if (selectedClaimState !== "any") {
      filteredClaims = filteredClaims.filter((claim) => parseInt(claim.claimStateId) === parseInt(selectedClaimState));
    }

    setTableData(filteredClaims);
  }, [rawData, selectedCountry, selectedYear, selectedStartMonth, selectedEndMonth, selectedClaimState]);

  useEffect(() => {
    applyFilters();
  }, [selectedCountry, selectedYear, selectedStartMonth, selectedEndMonth, selectedClaimState, applyFilters]);

  // Handle Filter button click
  const handleFilter = () => {
    applyFilters();
  };

  // Handle Reset button click
  const handleResetFilters = () => {
    setSelectedCountry("any");
    setSelectedYear("any");
    setSelectedStartMonth("any");
    setSelectedEndMonth("any");
    setSelectedClaimState("any");
    setTableData(rawData);
  };

  // Columns definition for DataGrid
  const columns = [
    {
      field: "claimId",
      headerName: "Claim ID",
      width: 50,
      hide: true,
    },
    {
      field: "entityId",
      headerName: "Customer Name",
      flex: 1.2,
      valueGetter: (params) => {
        const entity = entityData.find((entity) => entity.entityId === params.row.entityId);
        return entity ? entity.name : "";
      },
    },
    { field: "claimCode", headerName: "Claim Code", flex: 0.7 },
    { field: "year", headerName: "Year", flex: 0.3 },
    {
      field: "claimPeriod",
      headerName: "Claim Periode",
      flex: 0.4,
      valueGetter: (params) =>
        `${params.row.startMonth.toString().padStart(2, "0")}/${params.row.endMonth.toString().padStart(2, "0")}`,
    },
    {
      field: "claimStateId",
      headerName: "Claim State",
      flex: 0.6,
      valueGetter: (params) => {
        const state = claimStateData.find((state) => state.claimStateId === params.row.claimStateId);
        return state ? state.description : "";
      },
    },
    {
      field: "countryId",
      headerName: "Country",
      flex: 0.3,
      valueGetter: (params) => {
        const country = countryData.find((country) => country.countryId === params.row.countryId);
        return country ? country.countryCode : "";
      },
    },
    {
      field: "liters",
      headerName: "Liters",
      flex: 0.4,
      align: "right",
      valueFormatter: (params) => {
        return params.value.toFixed(2);
      },
    },
    {
      field: "claimAmountLocal",
      headerName: "Amount Local",
      flex: 0.4,
      align: "right",
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "15px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "claimAmountEUR",
      headerName: "Amount EUR",
      flex: 0.4,
      align: "right",
      renderCell: (params) => (
        <div style={{ textAlign: "right", paddingRight: "15px" }}>
          {params.value.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Delete Claim",
                  subTitle: `Are you sure you want to delete ${params.row.claimCode}?`,
                  onConfirm: () => {
                    onDeleteConfirmed(e, params.row);
                  },
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setViewExciseClaimDialog({
                  isOpen: true,
                  title: "View Excise Claim",
                  params: params.row,
                  countryData: countryData,
                  downloadFile: true,
                  custInfo: [
                    {
                      customerId: params.row.entityId,
                      customerNumber: entityData.find((entity) => entity.entityId === params.row.entityId)?.entityNumber || "",
                    },
                  ],
                  subTitle: `View details for ${params.row.claimCode}`,
                  onConfirm: () => {
                    onEditConfirmed(e, params.row);
                  },
                });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  // Notification state
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  // Confirm dialog state
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  // View excise claim dialog state
  const [viewExciseClaimDialog, setViewExciseClaimDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    custInfo: [],
  });

  // Handle edit confirmation (e.g., after viewing a claim)
  const onEditConfirmed = (e, params) => {
    setViewExciseClaimDialog({ ...viewExciseClaimDialog, isOpen: false });
  };

  // Handle delete confirmation
  const onDeleteConfirmed = (e, params) => {
    axios
      .delete("/api/v1/exciseClaims/" + params.claimId)
      .then((response) => {
        setNotify({
          isOpen: true,
          message: "Claim deleted successfully",
          type: "success",
        });
        fetchExciseClaimsByEntityId();
      })
      .catch((error) => {
        console.log(error);
        setNotify({
          isOpen: true,
          message: "Error deleting claim",
          type: "error",
        });
      })
      .finally(() => {
        setConfirmDialog({ ...confirmDialog, isOpen: false });
      });
  };

  // Function to get row class based on claim status
  const getRowClassName = (params) => {
    const { claimSequenceNumber, uploadStatus } = params.row;
    if (claimSequenceNumber > 0) {
      if (uploadStatus === "acknowledgement") {
        return "pastelGreen";
      } else if (uploadStatus !== "" && uploadStatus !== "acknowledgement") {
        return "pastelRed";
      } else if (uploadStatus === "") {
        return "pastelBlue";
      }
    }
    return "";
  };

  // Function to add dynamic styles for row coloring
  function addDynamicStyles(rules) {
    const styleEl = document.createElement("style");
    document.head.appendChild(styleEl);
    const styleSheet = styleEl.sheet;

    rules.forEach((rule) => {
      styleSheet.insertRule(rule, styleSheet.cssRules.length);
    });
  }

  // Add dynamic styles on component mount
  useEffect(() => {
    addDynamicStyles([
      `.pastelGreen { background-color: #b2f2bb; }`,
      `.pastelRed { background-color: #ffc9c9; }`,
      `.pastelBlue { background-color: #a5d8ff; }`,
    ]);
  }, []);

  // Color legend component
  const ColorLegend = () => (
    <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2} mb={2}>
      <Box display="flex" alignItems="center" mr={2}>
        <Box width={15} height={15} bgcolor="#b2f2bb" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          Claim upload status OK
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mr={2}>
        <Box width={15} height={15} bgcolor="#a5d8ff" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          Claim upload in progress
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box width={15} height={15} bgcolor="#ffc9c9" mr={0.5} />
        <Typography variant="body2" style={{ fontSize: "0.8rem" }}>
          Error while uploading claim
        </Typography>
      </Box>
    </Box>
  );

  // ======== BulkEditFooter Implementation ========
  // State for selected rows and totals
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedClaimCount, setSelectedClaimCount] = useState(0);
  const [selectedClaimTotalLiters, setSelectedClaimTotalLiters] = useState(0);
  const [selectedClaimTotalAmountLocal, setSelectedClaimTotalAmountLocal] = useState(0);
  const [selectedClaimTotalAmountEUR, setSelectedClaimTotalAmountEUR] = useState(0);

  // Handle selection changes to compute totals
  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
    let count = newSelection.length;
    let totalLiters = 0;
    let totalAmountLocal = 0;
    let totalAmountEUR = 0;

    newSelection.forEach((claimId) => {
      const selectedClaim = tableData.find((claim) => claim.claimId === claimId);
      if (selectedClaim) {
        totalLiters += selectedClaim.liters;
        totalAmountLocal += selectedClaim.claimAmountLocal;
        totalAmountEUR += selectedClaim.claimAmountEUR; // Adjust if claimAmountEUR represents VAT EUR
      }
    });

    setSelectedClaimCount(count);
    setSelectedClaimTotalLiters(totalLiters);
    setSelectedClaimTotalAmountLocal(totalAmountLocal);
    setSelectedClaimTotalAmountEUR(totalAmountEUR);
  };

  // BulkEditFooter Component Defined Inside to Access State Variables
  const BulkEditFooter = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderTop: "1px solid #ccc",
          paddingTop: 10,
          paddingLeft: 10,
        }}
      >
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total Selected Claims:</div>
            <div style={{ marginBottom: 5 }}>{selectedClaimCount}</div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total Liters:</div>
            <div style={{ marginBottom: 5 }}>
              {selectedClaimTotalLiters.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total Amount Local:</div>
            <div style={{ marginBottom: 5 }}>
              {selectedClaimTotalAmountLocal.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
            <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total Amount EUR:</div>
            <div style={{ marginBottom: 5 }}>
              {selectedClaimTotalAmountEUR.toLocaleString("nl-NL", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
        </div>
        {/* **Removed: Bulk Action Buttons** */}
        <div>
          <GridFooter
            style={{ borderTop: "none" }}
            pageSize={15} // Adjust if you have dynamic page sizes
            onPageSizeChange={() => {}}
            rowsPerPageOptions={[15, 50, 100]}
          />
        </div>
      </div>
    );
  };
  // ======== End of BulkEditFooter Implementation ========

  return (
    <React.Fragment>
      <Helmet title="Customer Excise Claims" />
      <Divider my={6} />
      <Card mb={6}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          {/* If you have an Add Claim Form in the future, uncomment and adjust accordingly */}
          {/* <Button
            variant="contained"
            color="primary"
            onClick={() => setIsAddFormOpen(true)}
          >
            Add Excise Claim
          </Button>
          <AddExciseClaimForm
            customerId={null} // Adjust based on your requirements
            customerNumber={null} // Adjust based on your requirements
            refreshGrid={fetchExciseClaimsByEntityId}
            isOpen={isAddFormOpen}
            onClose={() => setIsAddFormOpen(false)}
          /> */}
        </Box>
        <Notification notify={notify} setNotify={setNotify} />
        <Divider />
        {/* Filter Section */}
        <Grid container spacing={2} alignItems="center" sx={{ mt: 5, mb: 5, ml: 1, pr: 2 }}>
          <Grid item xs={2}>
            <SelectWrapper
              name="countryId"
              label="Country"
              options={[{ key: "any", value: "Any" }, ...countryData.map((c) => ({ key: c.countryId, value: c.countryCode }))]}
              value={selectedCountry}
              handleChange={handleCountryValue}
            />
          </Grid>
          <Grid item xs={2}>
            <SelectWrapper
              name="year"
              label="Year"
              options={[
                { key: "any", value: "Any" },
                ...Array.from({ length: 5 }, (_, i) => {
                  const year = new Date().getFullYear() - i;
                  return { key: year, value: year.toString() };
                }),
              ]}
              value={selectedYear}
              handleChange={handleYearValue}
            />
          </Grid>
          <Grid item xs={2}>
            <SelectWrapper
              name="startMonth"
              label="Start Month"
              options={[
                { key: "any", value: "Any" },
                ...Array.from({ length: 12 }, (_, i) => ({
                  key: i + 1,
                  value: (i + 1).toString().padStart(2, "0"),
                })),
              ]}
              value={selectedStartMonth}
              handleChange={handleStartMonthValue}
            />
          </Grid>
          <Grid item xs={2}>
            <SelectWrapper
              name="endMonth"
              label="End Month"
              options={[
                { key: "any", value: "Any" },
                ...Array.from({ length: 12 }, (_, i) => ({
                  key: i + 1,
                  value: (i + 1).toString().padStart(2, "0"),
                })),
              ]}
              value={selectedEndMonth}
              handleChange={handleEndMonthValue}
            />
          </Grid>
          <Grid item xs={2}>
            <SelectWrapper
              name="claimStateId"
              label="Claim State"
              options={[
                { key: "any", value: "Any" },
                ...claimStateData.map((state) => ({
                  key: state.claimStateId,
                  value: state.description,
                })),
              ]}
              value={selectedClaimState}
              handleChange={handleClaimStateValue}
            />
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Button variant="contained" sx={{ mr: 2 }} onClick={handleFilter}>
                Filter
              </Button>
              <Button variant="outlined" onClick={handleResetFilters}>
                Reset
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        {/* Color Legend */}
        <ColorLegend />
        {/* DataGrid Section */}
        <Grid container spacing={2} mt={0}>
          <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
          <ViewExciseClaimDialog
            viewExciseClaimDialog={viewExciseClaimDialog}
            setViewExciseClaimDialog={setViewExciseClaimDialog}
            refreshGrid={fetchExciseClaimsByEntityId}
          />
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" mb={2}>
              <DataGridExport tableData={tableData} columns={columns} />
            </Box>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="600px">
                <Typography sx={{ mr: 6, fontSize: 20 }}>Loading</Typography>
                <CircularProgress />
              </Box>
            ) : (
              countryData.length > 0 && (
                <DataGrid
                  rows={tableData}
                  columns={columns}
                  pageSize={15}
                  density="compact"
                  components={{
                    Toolbar: GridToolbar,
                    Footer: BulkEditFooter, // Integrate Custom Footer
                  }}
                  selectionModel={selectedRows}
                  onSelectionModelChange={handleSelectionChange}
                  checkboxSelection // Enable checkbox selection
                  getRowClassName={getRowClassName}
                  getRowId={(row) => row.claimId}
                  disableSelectionOnClick
                  disableColumnSelector
                  sx={{
                    height: 667,
                    width: "100%",
                    borderRadius: 1,
                    backgroundColor: "background.paper",
                    boxShadow: 2,
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main",
                    },
                    marginTop: 0,
                  }}
                  // componentsProps={{
                  //   toolbar: {
                  //     quickFilterProps: { debounceMs: 250 },
                  //   },
                  // }}
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: false },
                      printOptions: { disableToolbarButton: false },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  pagination
                  paginationMode="client"
                  rowsPerPageOptions={[15, 50, 100]}
                />
              )
            )}
          </Grid>
        </Grid>
      </Card>
      {/* Notification and Alerts */}
      <Box sx={{ width: "100%" }}>
        <Collapse in={notify.isOpen}>
          <Alert
            severity={notify.type}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setNotify({ ...notify, isOpen: false });
                }}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {notify.message}
          </Alert>
        </Collapse>
      </Box>
    </React.Fragment>
  );

  // ======== BulkEditFooter Implementation ========
  // BulkEditFooter Component Defined Inside to Access State Variables
  // function BulkEditFooter() {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "space-between",
  //         borderTop: "1px solid #ccc",
  //         paddingTop: 10,
  //       }}
  //     >
  //       <div style={{ display: "flex", alignItems: "flex-start" }}>
  //         <div style={{ display: "flex", flexDirection: "column" }}>
  //           <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total Selected Claims:</div>
  //           <div style={{ marginBottom: 5 }}>{selectedClaimCount}</div>
  //         </div>
  //         <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
  //           <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total Net Amount:</div>
  //           <div style={{ marginBottom: 5 }}>
  //             {selectedClaimTotalNetAmount.toLocaleString("nl-NL", {
  //               minimumFractionDigits: 2,
  //               maximumFractionDigits: 2,
  //             })}
  //           </div>
  //         </div>
  //         <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
  //           <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total VAT Local:</div>
  //           <div style={{ marginBottom: 5 }}>
  //             {selectedClaimTotalVATLocal.toLocaleString("nl-NL", {
  //               minimumFractionDigits: 2,
  //               maximumFractionDigits: 2,
  //             })}
  //           </div>
  //         </div>
  //         <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
  //           <div style={{ fontWeight: "bold", marginBottom: 5 }}>Total VAT EUR:</div>
  //           <div style={{ marginBottom: 5 }}>
  //             {selectedClaimTotalVATEUR.toLocaleString("nl-NL", {
  //               minimumFractionDigits: 2,
  //               maximumFractionDigits: 2,
  //             })}
  //           </div>
  //         </div>
  //       </div>
  //       {/* **Removed: Bulk Action Buttons** */}
  //       <div>
  //         <GridFooter
  //           style={{ borderTop: "none" }}
  //           pageSize={15} // Adjust if you have dynamic page sizes
  //           onPageSizeChange={() => {}}
  //           rowsPerPageOptions={[15, 50, 100]}
  //         />
  //       </div>
  //     </div>
  //   );
  // }
  // ======== End of BulkEditFooter Implementation ========
};

export default CustomerExciseClaims;
