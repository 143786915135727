import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Grid,
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  // Typography,
  Divider,
  Button,
  Alert,
  Collapse,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import apiUtils from "../../utils/apiUtils";
import ViewCheckAutomatedInvoiceDialog from "../../components/ViewCheckAutomatedInvoiceDialog";
import UploadStatusModal from "../../components/UploadStatusModal";
import SelectWrapper from "../../components/SelectBoxDataGridCell";
import moment from "moment";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

// Styled Components
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);

// Define errorCodesList outside the component to make it stable
const errorCodesList = [
  // Hard Errors
  { code: 0, description: "Experiment mislukt.", type: "hard" },
  { code: 1, description: "Experiment geslaagd.", type: "hard" },
  { code: 3, description: "Er is een error opgetreden.", type: "hard" },
  { code: 4, description: "Error bij het extraheren van JSON.", type: "hard" },
  { code: 5, description: "Schrijven naar database mislukt.", type: "hard" },
  {
    code: 10,
    description:
      "Het ophalen van een bedrag (float) tijdens de validatie-experimenten is mislukt. Waarschijnlijk een parsingfout. (OCR-fout bijvoorbeeld)",
    type: "hard",
  },
  {
    code: 22,
    description:
      "Vergelijking van bedragen is groter dan de toegestane marge of fout, maar het verschil tussen de waarden is kleiner dan 2 keer de marge. Waarschijnlijk een afrondingsfout.",
    type: "hard",
  },
  {
    code: 25,
    description:
      "De vergelijking van de bedragen is groter dan de toegestane marge of fout, maar het verschil tussen de waarden is kleiner dan 5 keer de marge. Waarschijnlijk een afrondingsfout.",
    type: "hard",
  },
  {
    code: 210,
    description:
      "De vergelijking van de bedragen is groter dan de toegestane marge of fout, maar het verschil tussen de waarden is kleiner dan 10 keer de marge. Waarschijnlijk een afrondingsfout.",
    type: "hard",
  },
  // Soft Errors have been removed from errorCodesList
];

// Define soft error codes and their descriptions
const softErrorDescriptionsMap = {
  612: "Invoice is older than 12 months.",
  621: "Invoice is older than 21 months.",
  63: "Invoice is between 3 and 6 months old.",
  66: "Invoice is between 6 and 12 months old.",
  50: "Supplier VAT ID not found.",
  55: "Suppliers country code is the same as the customers country code.",
  70: "Gasoline found on the invoice.",
  80: "Unknown customer",
  81: "Entity type other then customer",
  90: "No product/Unknown product",
  9: "VAT amount does not match the transaction data. (Currently only for MSTS)",
  22: "Comparison of amounts is greater than the allowed margin or error, but the difference between the values is less than 2 times the margin. Probably a rounding error.",
  25: "Comparison of amounts is greater than the allowed margin or error, but the difference between the values is less than 5 times the margin. Probably a rounding error.",
  210: "Comparison of amounts is greater than the allowed margin or error, but the difference between the values is less than 10 times the margin. Probably a rounding error.",
  220: "MSTS Denmark and Sweden",
  // Add more soft error codes and descriptions as needed
};

// Utility function to compute soft errors for a given invoice
const computeSoftErrorsForInvoice = (invoice, countryData, supplierData, entitiesData, productData) => {
  const softErrors = [];
  const now = new Date();
  const invoiceDate = new Date(invoice.invoiceDate);
  const diffInMonths = (now.getFullYear() - invoiceDate.getFullYear()) * 12 + (now.getMonth() - invoiceDate.getMonth());

  // console.log("Entities:", entitiesData);

  // Age-based soft errors
  if (diffInMonths >= 12 && diffInMonths < 21) {
    softErrors.push(612); // Invoice older than 12 months
  }
  if (diffInMonths >= 3 && diffInMonths < 6) {
    softErrors.push(63); // Invoice between 3 and 6 months old
  }
  if (diffInMonths >= 6 && diffInMonths < 12) {
    softErrors.push(66); // Invoice between 6 and 12 months old
  }
  if (diffInMonths >= 21) {
    softErrors.push(621); // Invoice older than 21 months
  }

  // Supplier VAT ID validation
  if (invoice.taxIdSupplier) {
    const countryCode = countryData.find((country) => invoice.taxIdSupplier.startsWith(country.countryCode));
    const vatIdToCheck = countryCode ? invoice.taxIdSupplier.slice(countryCode.countryCode.length) : invoice.taxIdSupplier;
    if (!supplierData.find((supplier) => supplier.supplierVATnr === vatIdToCheck)) {
      softErrors.push(50); // Supplier VAT ID not found
    }
  }

  // Country code comparison
  if (invoice.countryCodeCustomer === invoice.countryCodeSupplier) {
    softErrors.push(55); // Suppliers country code is the same as the customer's country code
  }

  // Benzine encounter validation
  if (invoice.encounteredBenzine && (invoice.countryCodeSupplier === "BE" || invoice.countryCodeSupplier === "PL")) {
    softErrors.push(70); // Error if encountered Benzine is true and countryCodeSupplier is NL or PL
  }

  // Customer validation
  const customer = entitiesData.find((user) => user.vat === invoice.taxIdCustomer && user.typeRelation === 1);
  if (!customer) {
    softErrors.push(80); // Unknown customer
  } else if (customer.typeRelation !== 1) {
    softErrors.push(81); // Entity type other than customer
  }

  // Product validation
  if (!invoice.productId || !productData.some((product) => product.productTypeId === Number(invoice.productId))) {
    softErrors.push(90); // Error if productId is null, empty, or not found in productData
  }

  // Supplier-specific soft errors
  const foundSupplier = supplierData.find((supplier) => {
    if (invoice.taxIdSupplier) {
      const countryCode = countryData.find((country) => country.countryCode && invoice.taxIdSupplier.startsWith(country.countryCode));
      const vatIdToCheck = countryCode ? invoice.taxIdSupplier.slice(countryCode.countryCode.length) : invoice.taxIdSupplier;
      return supplier.supplierVATnr === vatIdToCheck;
    }
    return false;
  });

  if (foundSupplier) {
    if (foundSupplier.supplierCode === 2111 || foundSupplier.supplierCode === 2113) {
      softErrors.push(220); // Push 220 if supplierCode matches
    }
  }

  return softErrors;
};

const InvoiceOverviewComponent = () => {
  // State Variables
  const pageSize = 15;
  const [page, setPage] = useState(0);
  const [activeTab, setActiveTab] = useState(0); // 0 for 'All Invoices' tab
  // const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("any");
  const [selectedUser, setSelectedUser] = useState("any");
  const [selectedYear, setSelectedYear] = useState("any");
  const [selectedStartMonth, setSelectedStartMonth] = useState("any");
  const [selectedEndMonth, setSelectedEndMonth] = useState("any");
  const [selectedState, setSelectedState] = useState("any");
  const [fetchedInvoiceData, setFetchedInvoiceData] = useState([]); // Data fetched from server
  const [localInvoiceUpdates, setLocalInvoiceUpdates] = useState({}); // Local updates keyed by invoiceId
  const [openAlert, setOpenAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [alertMessage, setAlertMessage] = useState("");
  const [rightPaneSize, setRightPaneSize] = useState(0);
  const [lastRightPaneSize, setLastRightPaneSize] = useState("50%");
  const [highlightedRowId, setHighlightedRowId] = useState(null);
  const [viewCustomerInvoiceDialog, setViewCustomerInvoiceDialog] = useState({
    isOpen: false,
    downloadFile: true,
    params: {},
  });
  const containerRef = useRef(null);
  const [selectedBatchId, setSelectedBatchId] = useState("any");
  const [batchIdOptions, setBatchIdOptions] = useState([]);
  // const [errorCodeOptions, setErrorCodeOptions] = useState([]);

  // New State Variables for Upload
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const uploadInProgress = false; // Placeholder for actual upload status
  const [uploadResults, setUploadResults] = useState([]); // Optional: To store individual upload results

  // Existing data fetchers
  const supplierData = apiUtils.useApiData("/api/v1/suppliers");
  const currencyData = apiUtils.useApiData("/api/v1/currencies");
  const productData = apiUtils.useApiData("/api/v1/productTypes");
  const entitiesData = apiUtils.useApiData("/api/v1/entities");
  const dwsUserData = apiUtils.useApiData("/api/v1/GetDWSUsers");
  const countryData = apiUtils.useApiData("/api/v1/countries");
  const invoiceStateData = apiUtils.useApiData("/api/v1/invoiceStates");
  // const [selectedErrorCodes, setSelectedErrorCodes] = useState([]);

  const [deleteInProgress, setDeleteInProgress] = useState(false);
  // const [deleteResults, setDeleteResults] = useState([]);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  // Memoize hardErrorCodes
  const hardErrorCodes = useMemo(() => errorCodesList.filter((error) => error.type === "hard").map((error) => error.code), []);

  // Define allowed soft error codes
  const allowedSoftErrorCodes = useMemo(() => [612, 63, 66, 70], []);
  const [selectionEnabled, setSelectionEnabled] = useState(false);

  // Compute allInvoiceData by merging fetched data and local updates
  const allInvoiceData = useMemo(() => {
    return fetchedInvoiceData.map((invoice) => {
      if (localInvoiceUpdates[invoice.invoiceId]) {
        return { ...invoice, ...localInvoiceUpdates[invoice.invoiceId] };
      }
      return invoice;
    });
  }, [fetchedInvoiceData, localInvoiceUpdates]);

  // Compute filteredData based on current filters and active tab
  const filteredData = useMemo(() => {
    let data = allInvoiceData;

    // console.table(data);

    // Apply Non-Tab Filters
    if (selectedCountry !== "any") {
      data = data.filter((inv) => inv.countryCodeCustomer === selectedCountry);
    }

    if (selectedUser !== "any") {
      data = data.filter((inv) => inv.uploadedBy === selectedUser);
    }

    if (selectedBatchId !== "any") {
      data = data.filter((inv) => inv.batchId === selectedBatchId);
    }

    if (selectedYear !== "any") {
      const selectedYearInt = parseInt(selectedYear, 10);
      data = data.filter((inv) => new Date(inv.invoiceDate).getFullYear() === selectedYearInt);
    }

    if (selectedStartMonth !== "any") {
      const startMonthInt = parseInt(selectedStartMonth, 10);
      data = data.filter((inv) => new Date(inv.invoiceDate).getMonth() + 1 >= startMonthInt);
    }

    if (selectedEndMonth !== "any") {
      const endMonthInt = parseInt(selectedEndMonth, 10);
      data = data.filter((inv) => new Date(inv.invoiceDate).getMonth() + 1 <= endMonthInt);
    }

    if (selectedState !== "any") {
      data = data.filter((inv) => inv.invoiceStateId === selectedState);
    }

    // Apply Tab-Based Filters

    if (activeTab === 0) {
      // Passed Invoices: No hard errors and no soft errors
      data = data.filter((inv) => inv.succeeded === true);
    } else if (activeTab === 1) {
      // Passed Invoices: No hard errors and no soft errors
      data = data.filter(
        (inv) => (!inv.errorCodes || inv.errorCodes.length === 0) && (!inv.softErrors || inv.softErrors.length === 0)
      );
    } else if (activeTab === 2) {
      // Soft Error Invoices: Any soft errors present and no hard errors
      data = data.filter((inv) => inv.softErrors && inv.softErrors.length > 0 && (!inv.errorCodes || inv.errorCodes.length === 0));
    } else if (activeTab === 3) {
      // Hard Error Invoices: At least one hard error
      data = data.filter((inv) => {
        if (inv.errorCodes && inv.errorCodes.length > 0) {
          return inv.errorCodes.some((code) => hardErrorCodes.includes(code));
        }
        return false;
      });
    }

    console.log(data);

    return data;
  }, [
    allInvoiceData,
    selectedCountry,
    selectedUser,
    selectedBatchId,
    selectedYear,
    selectedStartMonth,
    selectedEndMonth,
    selectedState,
    activeTab,
    hardErrorCodes,
  ]);

  // Compute counts for each tab
  // Compute counts based on current filters, excluding activeTab
  const tabCounts = useMemo(() => {
    let data = allInvoiceData;

    // Apply Non-Tab Filters
    if (selectedCountry !== "any") {
      data = data.filter((inv) => inv.countryCodeCustomer === selectedCountry);
    }

    if (selectedUser !== "any") {
      data = data.filter((inv) => inv.uploadedBy === selectedUser);
    }

    if (selectedBatchId !== "any") {
      data = data.filter((inv) => inv.batchId === selectedBatchId);
    }

    if (selectedYear !== "any") {
      const selectedYearInt = parseInt(selectedYear, 10);
      data = data.filter((inv) => new Date(inv.invoiceDate).getFullYear() === selectedYearInt);
    }

    if (selectedStartMonth !== "any") {
      const startMonthInt = parseInt(selectedStartMonth, 10);
      data = data.filter((inv) => new Date(inv.invoiceDate).getMonth() + 1 >= startMonthInt);
    }

    if (selectedEndMonth !== "any") {
      const endMonthInt = parseInt(selectedEndMonth, 10);
      data = data.filter((inv) => new Date(inv.invoiceDate).getMonth() + 1 <= endMonthInt);
    }

    if (selectedState !== "any") {
      data = data.filter((inv) => inv.invoiceStateId === selectedState);
    }

    // Calculate counts based on the filtered data
    const allCount = data.filter((invoice) => invoice.succeeded === true).length;

    const passedCount = data.filter(
      (invoice) => (!invoice.errorCodes || invoice.errorCodes.length === 0) && (!invoice.softErrors || invoice.softErrors.length === 0)
    ).length;

    const softErrorCount = data.filter(
      (invoice) => invoice.softErrors && invoice.softErrors.length > 0 && (!invoice.errorCodes || invoice.errorCodes.length === 0)
    ).length;

    const hardErrorCount = data.filter((invoice) => {
      if (invoice.errorCodes && invoice.errorCodes.length > 0) {
        return invoice.errorCodes.some((code) => hardErrorCodes.includes(code));
      }
      return false; // Exclude invoices without errorCodes
    }).length;

    return {
      all: allCount,
      passed: passedCount,
      softError: softErrorCount,
      hardError: hardErrorCount,
    };
  }, [
    allInvoiceData,
    selectedCountry,
    selectedUser,
    selectedBatchId,
    selectedYear,
    selectedStartMonth,
    selectedEndMonth,
    selectedState,
    hardErrorCodes,
  ]);

  // Fetch InvoiceOverview Data without Tab Filter
  const fetchInvoiceOverviewData = useCallback(async () => {
    // setLoading(true);
    try {
      const response = await axios.get("/api/v1/ocr/getOCRScannedInvoices");
      const fetchedInvoices = response.data;
      // console.log("Fetched InvoiceOverview Data", fetchedInvoices);

      // Check if countryData is loaded
      if (!countryData || countryData.length === 0) {
        console.warn("Country data is not yet loaded. Skipping soft error computation.");
        // setLoading(false);
        return; // Exit early if countryData is not available
      }

      // Process fetched invoices
      const processedFetchedInvoices = fetchedInvoices.map((invoice) => {
        let processedErrorMessages = [];
        let processedErrorCodes = [];
        let processedSoftErrorDesc = [];

        // Process errorMessages (only for hard errors)
        if (invoice.errorMessages) {
          if (Array.isArray(invoice.errorMessages)) {
            // Assuming errorMessages array corresponds to hard errors
            processedErrorMessages = invoice.errorMessages;
          } else if (typeof invoice.errorMessages === "string") {
            // Split string into array, assuming each line is a hard error message
            processedErrorMessages = invoice.errorMessages.split(/\r?\n/).filter((msg) => msg.trim() !== "");
          }
        }

        // Process errorCodes (only hard errors)
        if (invoice.errorCodes) {
          if (typeof invoice.errorCodes === "string") {
            processedErrorCodes = invoice.errorCodes
              .split(",")
              .map((code) => parseInt(code.trim(), 10))
              .filter((code) => !isNaN(code) && hardErrorCodes.includes(code));
          } else if (Array.isArray(invoice.errorCodes)) {
            processedErrorCodes = invoice.errorCodes.map((code) => parseInt(code, 10)).filter((code) => hardErrorCodes.includes(code));
          }
        }

        invoice.currencyId = countryData.find((country) => country.countryCode === invoice.countryCodeSupplier)?.currency || "";

        // Compute soft errors based on invoiceDate
        const computedSoftErrors = computeSoftErrorsForInvoice(invoice, countryData, supplierData, entitiesData, productData);

        // Map soft error codes to descriptions
        processedSoftErrorDesc = computedSoftErrors.map(
          (code) => softErrorDescriptionsMap[code] || `Unknown soft error code: ${code}`
        );

        return {
          ...invoice,
          errorMessages: processedErrorMessages || [], // Ensure it's an array
          errorCodes: processedErrorCodes || [], // Ensure it's an array
          softErrors: computedSoftErrors || [], // Ensure it's an array
          softErrorDesc: processedSoftErrorDesc || [], // Renamed to avoid shadowing
        };
      });

      setFetchedInvoiceData(processedFetchedInvoices);
    } catch (error) {
      console.error("Error fetching InvoiceOverview data", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to fetch invoice data.");
      setOpenAlert(true);
      setTimeout(() => setOpenAlert(false), 5000);
    } finally {
      // setLoading(false);
    }
  }, [hardErrorCodes, countryData, supplierData, entitiesData, productData]);

  // Fetch InvoiceOverview Data when component mounts or when filters change
  useEffect(() => {
    fetchInvoiceOverviewData();
  }, [fetchInvoiceOverviewData]);

  // Handle Filter Changes
  const handleFilter = () => {
    fetchInvoiceOverviewData();
  };

  // Handle Reset Filters
  const handleResetFilter = () => {
    setSelectedCountry("any");
    setSelectedUser("any");
    setSelectedBatchId("any");
    setSelectedYear("any");
    setSelectedStartMonth("any");
    setSelectedEndMonth("any");
    setSelectedState("any");
    fetchInvoiceOverviewData();
  };

  // DataGrid Columns Configuration
  const defaultColumns = useMemo(
    () => [
      {
        field: "customerNumber",
        headerName: "Customer Number",
        flex: 1,
        hide: false,
      },
      {
        field: "currencyId",
        headerName: "Currency ID",
        flex: 1,
        hide: false,
      },
      {
        field: "batchId",
        headerName: "Batch ID",
        flex: 0.5,
        hide: false,
      },
      {
        field: "batchRate",
        headerName: "Batch Rate",
        flex: 0.5,
        hide: false,
        renderCell: (params) => {
          const { batchTotalDocuments, batchMatchingDocuments } = params.row;

          // Data Validation: Ensure values are numbers
          const total = typeof batchTotalDocuments === "number" ? batchTotalDocuments : 0;
          const matching = typeof batchMatchingDocuments === "number" ? batchMatchingDocuments : 0;

          const isEqual = matching === total;
          const displayText = `${matching} / ${total}`;

          return <span style={{ color: isEqual ? "inherit" : "#F08080" }}>{displayText}</span>;
        },
      },
      {
        field: "invoiceNumber",
        headerName: "Invoice Number",
        flex: 2,
        hide: false,
      },
      {
        field: "invoiceDate",
        headerName: "Invoice Date",
        flex: 2,
        type: "date",
        valueFormatter: (params) => (params.value ? new Date(params.value).toLocaleDateString() : ""),
      },
      {
        field: "errorCodesCombined",
        headerName: "Error Codes",
        width: 100, // Adjust width as needed
        renderCell: (params) => {
          const { errorCodes, softErrors } = params.row;

          const hasHardErrors = errorCodes && errorCodes.length > 0;
          const hasSoftErrors = softErrors && softErrors.length > 0;

          if (!hasHardErrors && !hasSoftErrors) {
            return "";
          }

          const hardErrorDisplay = hasHardErrors ? `${errorCodes.join(", ")}` : "";
          const softErrorDisplay = hasSoftErrors ? `${softErrors.join(", ")}` : "";

          // Combine hard and soft error displays with a separator if both exist
          const combinedDisplay = [hardErrorDisplay, softErrorDisplay]
            .filter(Boolean) // Removes empty strings
            .join(", ");

          return combinedDisplay;
        },
      },
      {
        field: "checkProgress",
        headerName: "Check Progress",
        width: 130,
        renderCell: (params) => {
          const {
            succesFullNrChecks,
            totalNrChecks,
            errorMessages,
            errorCodes,
            softErrors,
            softErrorDesc, // Updated field name
          } = params.row;

          if (totalNrChecks === undefined || succesFullNrChecks === undefined || totalNrChecks === 0) {
            return "N/A"; // Handle case when the fields are missing or total is zero
          }

          // Determine if there are hard errors or soft errors
          const hasHardErrors = errorCodes && errorCodes.length > 0;
          const hasSoftErrors = softErrors && softErrors.length > 0;

          // Calculate the number of soft and hard errors
          const softErrorCount = softErrorDesc.length;

          // Calculate hardErrorCount by filtering errorCodes that are hard errors
          const hardErrorCount = errorCodes.filter((code) => hardErrorCodes.includes(code)).length;

          // Adjust totalNrChecks by adding the number of soft errors
          const adjustedTotalNrChecks = totalNrChecks + softErrorCount;

          // Calculate succesFullNrChecks as totalNrChecks minus soft and hard error counts
          const adjustedSuccesFullNrChecks = Math.max(adjustedTotalNrChecks - softErrorCount - hardErrorCount, 0);

          // Determine the background color based on the error presence
          let bgColor;
          if (hasHardErrors) {
            bgColor = "#d32f2f"; // Red
          } else if (hasSoftErrors) {
            bgColor = "#ed6c02"; // Orange
          } else {
            bgColor = "#2e7d32"; // Green
          }

          // Extract RGB values with null checks
          const rgbMatch = bgColor.match(/\d+/g);
          const [r, g, b] = rgbMatch ? rgbMatch.map(Number) : [0, 0, 0];

          // Determine the text color based on the background color brightness
          const brightness = (r * 299 + g * 587 + b * 114) / 1000;
          const textColor = brightness > 125 ? "black" : "white";

          // Prepare the tooltip content as a bullet list
          let tooltipContent = (
            <div>
              <div>Check Progress:</div>
              <ul style={{ paddingLeft: "16px", margin: 0 }}>
                {Array.isArray(errorMessages) && errorMessages.length > 0 && (
                  <>
                    {errorMessages.map((msg, index) => (
                      <li key={index}>{msg}</li>
                    ))}
                  </>
                )}
                {Array.isArray(softErrorDesc) && softErrorDesc.length > 0 && (
                  <>
                    {softErrorDesc.map((desc, index) => (
                      <li key={`soft-${index}`}>{desc}</li>
                    ))}
                  </>
                )}
              </ul>
            </div>
          );

          return (
            <Tooltip title={tooltipContent} arrow>
              <Box
                sx={{
                  width: "100%",
                  padding: "8px",
                  borderRadius: "4px",
                  textAlign: "center",
                  backgroundColor: bgColor,
                  color: textColor,
                  cursor: hasHardErrors || hasSoftErrors ? "pointer" : "default", // Change cursor if tooltip is present
                }}
              >
                {adjustedSuccesFullNrChecks} / {adjustedTotalNrChecks}
              </Box>
            </Tooltip>
          );
        },
      },
    ],
    [hardErrorCodes]
  );

  // Definieer kolommen voor Hard Error Invoices tab
  const hardErrorColumns = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        flex: 1,
      },
      {
        field: "batchId",
        headerName: "Batch ID",
        flex: 0.5,
        hide: false,
      },
      {
        field: "batchRate",
        headerName: "Batch Rate",
        flex: 1, // Adjust flex as needed for layout
        valueFormatter: (params) => `${params.value}%`, // Optional: Format as percentage
      },
      {
        field: "errorCodes",
        headerName: "Error Codes",
        flex: 1,
      },
      {
        field: "errorMessages",
        headerName: "Error Messages",
        flex: 2,
      },
      {
        field: "originalUploadFileName",
        headerName: "Original Upload File",
        flex: 2,
      },
    ],
    []
  );

  const columns = useMemo(() => {
    if (activeTab === 3) {
      return hardErrorColumns;
    }
    return defaultColumns;
  }, [activeTab, defaultColumns, hardErrorColumns]);

  // Define the selectRow function
  const selectRow = useCallback(
    (row) => {
      setHighlightedRowId(row.invoiceId);
      setViewCustomerInvoiceDialog({
        isOpen: true,
        downloadFile: true,
        params: row,
      });
      setRightPaneSize(lastRightPaneSize);
    },
    [lastRightPaneSize]
  );

  // Update handleRowClick to use selectRow
  const handleRowClick = (params, event) => {
    event.defaultMuiPrevented = true;
    selectRow(params.row);
  };

  // Add selectionModel state
  const [selectionModel, setSelectionModel] = useState([]);

  // Update handleCellKeyDown to handle arrow keys and +/-
  const handleCellKeyDown = useCallback(
    (params, event) => {
      const { softErrors } = params.row;
      const selectionAllowed =
        softErrors && softErrors.length > 0 ? softErrors.every((code) => allowedSoftErrorCodes.includes(code)) : true;

      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault(); // Prevent default navigation

        // Get current visible rows
        const startIndex = page * pageSize;
        const endIndex = startIndex + pageSize;
        const visibleRows = filteredData.slice(startIndex, endIndex);

        // Find the index of the current row within the visible rows
        const rowIndexInVisibleRows = visibleRows.findIndex((row) => row.id === params.id);

        if (rowIndexInVisibleRows === -1) {
          // Row not found in visible rows, something is wrong
          return;
        }

        // Prevent moving focus to the header when on the top row of the first page
        if (event.key === "ArrowUp" && rowIndexInVisibleRows === 0 && page === 0) {
          // Prevent the default behavior
          event.preventDefault();

          // Stop propagation to ensure no other focus management takes over
          event.stopPropagation();

          // Refocus on the current cell to keep the focus within the grid
          const currentCellSelector = `[data-id="${params.id}"] [data-field="${params.field}"]`;
          const currentCell = document.querySelector(currentCellSelector);

          if (currentCell) {
            currentCell.focus();
          }
          return; // Exit the function to prevent any further handling
        }

        // Handle ArrowUp at the top of the grid but not on the first page
        if (event.key === "ArrowUp" && rowIndexInVisibleRows === 0) {
          if (page > 0) {
            const newPage = page - 1;
            setPage(newPage);
            setTimeout(() => {
              const newStartIndex = newPage * pageSize;
              const newVisibleRows = filteredData.slice(newStartIndex, newStartIndex + pageSize);
              const lastRow = newVisibleRows[newVisibleRows.length - 1];

              if (lastRow) {
                const cellSelector = `[data-id="${lastRow.id}"] [data-field="${params.field}"]`;
                const cell = document.querySelector(cellSelector);

                if (cell) {
                  cell.focus();
                  selectRow(lastRow);
                }
              }
            }, 100);
          }
        }

        // Handle ArrowDown at the bottom of the grid to prevent focus from moving out of the grid
        else if (event.key === "ArrowDown" && rowIndexInVisibleRows === visibleRows.length - 1) {
          const totalPages = Math.ceil(filteredData.length / pageSize);
          if (page < totalPages - 1) {
            // Move to the first row of the next page
            const newPage = page + 1;
            setPage(newPage);
            setTimeout(() => {
              const newStartIndex = newPage * pageSize;
              const newVisibleRows = filteredData.slice(newStartIndex, newStartIndex + pageSize);
              const firstRow = newVisibleRows[0];

              if (firstRow) {
                const cell = document.querySelector(`[data-id="${firstRow.id}"] [data-field="${params.field}"]`);
                if (cell) {
                  cell.focus();
                  selectRow(firstRow);
                }
              }
            }, 100);
          } else {
            // Prevent moving focus out of the grid if at the bottom row of the last page
            event.preventDefault();
          }
        }

        // Normal navigation within the page
        else {
          setTimeout(() => {
            const focusedCell = document.activeElement;
            const rowElement = focusedCell.closest("[data-id]");
            if (rowElement) {
              const rowId = rowElement.getAttribute("data-id");
              if (rowId) {
                const newRow = filteredData.find((row) => row.id.toString() === rowId);
                if (newRow) {
                  selectRow(newRow);
                }
              }
            }
          }, 0);
        }
      }

      // Other key handling (selection logic)
      else if (event.key === "+" && selectionAllowed) {
        const rowId = params.id;
        setSelectionModel((prevSelection) => (!prevSelection.includes(rowId) ? [...prevSelection, rowId] : prevSelection));
      } else if (event.key === "-" && selectionAllowed) {
        const rowId = params.id;
        setSelectionModel((prevSelection) =>
          prevSelection.includes(rowId) ? prevSelection.filter((id) => id !== rowId) : prevSelection
        );
      } else if (event.key === " " && selectionAllowed) {
        event.preventDefault();
        const rowId = params.id;
        setSelectionModel((prevSelection) =>
          prevSelection.includes(rowId) ? prevSelection.filter((id) => id !== rowId) : [...prevSelection, rowId]
        );
      }
    },
    [filteredData, selectRow, page, pageSize, allowedSoftErrorCodes]
  );

  // Handle Pane Change
  const handlePaneChange = (size) => {
    setRightPaneSize(size);
    if (size !== 0) {
      setLastRightPaneSize(size);
    }
  };

  // Optional: Effect to manage focus when the dialog opens
  useEffect(() => {
    if (viewCustomerInvoiceDialog.isOpen && containerRef.current) {
      // Assuming the dialog has a focusable element with id 'dialog-content'
      const dialogContent = containerRef.current.querySelector("#dialog-content");
      if (dialogContent) {
        dialogContent.focus();
      }
    }
  }, [viewCustomerInvoiceDialog.isOpen]);
  const [updateTimestamp, setUpdateTimestamp] = useState(null);
  // Refactored updateInvoiceInTable function
  const updateInvoiceInTable = useCallback(
    (updatedInvoice) => {
      // Recompute softErrors for the updated invoice
      const recomputedSoftErrors = computeSoftErrorsForInvoice(updatedInvoice, countryData, supplierData, entitiesData, productData);

      // Map soft error codes to descriptions
      const recomputedSoftErrorDesc = recomputedSoftErrors.map(
        (code) => softErrorDescriptionsMap[code] || `Unknown soft error code: ${code}`
      );

      // Update local invoice updates without affecting filters
      setLocalInvoiceUpdates((prevUpdates) => ({
        ...prevUpdates,
        [updatedInvoice.invoiceId]: {
          ...updatedInvoice,
          softErrors: recomputedSoftErrors || [],
          softErrorDesc: recomputedSoftErrorDesc || [],
        },
      }));

      setUpdateTimestamp(Date.now());

      // console.log("Updated invoice in local state", updatedInvoice);
      // console.log(localInvoiceUpdates);

      // Removed the setSelectedBatchId call to prevent unintended re-renders
    },
    [countryData, supplierData, entitiesData, productData]
  );

  // Handle User Select Value
  const handleUserSelectValue = (value) => {
    setSelectedUser(value);

    // Reset Batch ID to 'any' if Uploaded By is set to 'any'
    if (value === "any") {
      setSelectedBatchId("any");
    }
  };

  // Handle Batch ID Select Value
  const handleBatchIdValue = (value) => {
    setSelectedBatchId(value);
  };

  // Handle Year Select Value
  // const handleYearValue = (value) => {
  //   setSelectedYear(value);
  // };

  // Handle Start Month Select Value
  // const handleStartMonthValue = (value) => {
  //   setSelectedStartMonth(value);
  // };

  // Handle End Month Select Value
  // const handleEndMonthValue = (value) => {
  //   setSelectedEndMonth(value);
  // };

  // Handle State Select Value
  // const handleStateValue = (value) => {
  //   setSelectedState(value);
  // };

  const extractVATNumber = (taxId, countryArr) => {
    if (!taxId) return "";
    const sortedCountries = [...countryArr].sort((a, b) => b.countryCode.length - a.countryCode.length);
    const matchedCountry = sortedCountries.find((country) => taxId.toUpperCase().startsWith(country.countryCode.toUpperCase()));
    return matchedCountry ? taxId.slice(matchedCountry.countryCode.length) : taxId;
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = `${d.getMonth() + 1}`.padStart(2, "0"); // Months are zero-based
    const day = `${d.getDate()}`.padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Handle Upload Invoices
  const handleUploadInvoices = useCallback(async () => {
    // Retrieve selected invoices based on selectionModel
    const selectedInvoices = filteredData.filter((invoice) => selectionModel.includes(invoice.id));

    if (selectedInvoices.length === 0) {
      setAlertSeverity("warning");
      setAlertMessage("No invoices selected for upload.");
      setOpenAlert(true);
      setTimeout(() => setOpenAlert(false), 5000);
      return;
    }

    setIsUploadModalOpen(true); // Open the modal
    setUploadProgress(0); // Reset progress
    setUploadResults([]); // Reset results

    const results = [];
    for (let i = 0; i < selectedInvoices.length; i++) {
      const invoice = selectedInvoices[i];

      try {
        // Prepare the payload based on CustomerInvoicesCreateDto
        let entityId = entitiesData.find((entity) => entity.vat === invoice.taxIdCustomer && entity.typeRelation === 1)?.entityId; // entity type controlleren
        const fullTaxIdSupplier = invoice.taxIdSupplier;
        const extractedVATnr = extractVATNumber(fullTaxIdSupplier, countryData);
        let supplier = supplierData.find((supplier) => supplier.supplierVATnr === extractedVATnr) || "";
        let invoiceCountryTemp = invoice.countryCode || supplier.countryCode;
        let invoiceCountry = countryData.find((country) => country.countryId === invoiceCountryTemp);
        let invoiceCurrency = invoiceCountry.currency;

        // Step 1: Create File Metadata
        const fileInfo = {
          documentType: 1,
          creationDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
          createdBy: 1,
          fileName: invoice.newFileName, // Assuming invoice.newFileName is the existing file name
          fileSize: invoice.fileSize, // Ensure you have this information
          mimeType: "application/pdf", // Assuming PDF, adjust if different
        };

        // Step 2: Get fileId by posting fileInfo
        const fileResponse = await axios.post("/api/v1/file", fileInfo);
        const fileId = fileResponse.data.fileId;
        let modelUsed = invoice.modelUsed;
        let fileName = invoice.newFileName;

        // Step 3: Download the existing file
        const downloadResponse = await axios({
          url: "/api/v1/azureocrblobstorage/" + fileName + "/" + modelUsed + "/0",
          method: "GET",
          responseType: "blob",
        });
        const downloadedFile = downloadResponse.data;

        // Step 4: Upload the file with the new filename {fileId}.pdf
        // const newFileName = `${fileId}.pdf`;
        const formData = new FormData();
        formData.append("file", downloadedFile, fileId);

        // Assuming you have an upload endpoint like /api/v1/azureblobstorage/upload
        const uploadResponse = await axios.post("/api/v1/azureblobstorage", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (uploadResponse.status !== 200 && uploadResponse.status !== 201) {
          throw new Error(`Failed to upload file for invoice ${invoice.invoiceNumber}`);
        }

        // Step 5: Update payload with fileId
        const payload = {
          entityId: entityId,
          invoiceNumber: invoice.invoiceNumber,
          invoiceDate: formatDate(invoice.invoiceDate), // Ensure correct date format
          supplierId: supplier.supplierId,
          countryCode: invoice.countryCode ?? supplier.countryCode,
          claimProductTypeId: invoice.productId,
          CurrencyId: invoice.currencyId ?? invoiceCurrency,
          invoiceGrossAmount: invoice.totalExcludingTax,
          invoiceVATAmountLocalCurrency: invoice.totalTax.toFixed(2),
          invoiceVATAmountEUR: 0,
          dataEntryDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
          dataEntryOperator: "System",
          invoiceStateId: invoice.invoiceStateId ?? 3,
          preFinanceId: invoice.preFinanceId ?? 0, // Default to 0 if undefined
          fileId: fileId, // Updated with the obtained fileId
        };

        // Step 6: Insert into the database
        const response = await axios.post("/api/v1/customerInvoices", payload);
        // console.log(`Invoice ${invoice.invoiceNumber} uploaded successfully:`, response.data);

        // Step 7: Set ProcessedByDWS
        await axios.put("/api/v1/ocr/setProcessedByDWS", {
          id: invoice.id,
          processedByDWS: true,
        });

        // Step 8: Remove the processed invoice from the fetchedInvoiceData
        setFetchedInvoiceData((prevData) => prevData.filter((inv) => inv.id !== invoice.id));

        results.push({
          customerNumber: invoice.customerNumber,
          invoiceNumber: invoice.invoiceNumber,
          status: "Success",
          data: response.data,
        });
      } catch (error) {
        // Check for specific "duplicate key" error
        const errorMessage = error.response?.data || error.message;
        // console.log("Error:", error);
        // console.log("Error Message:", errorMessage);
        const displayError = errorMessage.includes("Cannot insert duplicate key row in object") ? "Possible duplicate" : errorMessage;

        // Failure result with custom error message if duplicate key error
        results.push({
          customerNumber: invoice.customerNumber,
          invoiceNumber: invoice.invoiceNumber,
          status: "Failed",
          error: displayError,
        });
      }

      // Update progress after each upload
      const progress = Math.round(((i + 1) / selectedInvoices.length) * 100);
      setUploadProgress(progress); // Update modal progress
    }

    setUploadResults(results); // Set results in the modal
    setIsUploadModalOpen(true); // Ensure modal stays open until user closes it

    // Prepare feedback messages
    const successCount = results.filter((res) => res.status === "Success").length;
    const failureCount = results.filter((res) => res.status === "Failed").length;

    if (failureCount === 0) {
      setAlertSeverity("success");
      setAlertMessage(`${successCount} invoice(s) uploaded successfully.`);
    } else if (successCount === 0) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to upload ${failureCount} invoice(s).`);
    } else {
      setAlertSeverity("warning");
      setAlertMessage(`Uploaded ${successCount} invoice(s) successfully. Failed to upload ${failureCount} invoice(s).`);
    }
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 7000);

    // Optional: Refresh the data to reflect uploaded invoices
    fetchInvoiceOverviewData();
  }, [filteredData, selectionModel, fetchInvoiceOverviewData, entitiesData, supplierData, countryData]);

  // Define isRowSelectable function
  const isRowSelectable = useCallback(
    (params) => {
      if (selectionEnabled) return true; // All rows selectable when enabled

      if (activeTab === 3) {
        return true; // All rows selectable when Tab 3 is active
      }

      const { softErrors } = params.row;

      if (softErrors && softErrors.length > 0) {
        // Only selectable if all softErrors are allowed
        return softErrors.every((code) => allowedSoftErrorCodes.includes(code));
      }

      // If no softErrors, row is selectable
      return true;
    },
    [activeTab, allowedSoftErrorCodes, selectionEnabled]
  );

  // Add this useEffect after your existing useEffect hooks
  useEffect(() => {
    if (!selectionEnabled) {
      // When selection is disabled, filter selectionModel to only allowed rows
      const newSelection = selectionModel.filter((id) => {
        const row = filteredData.find((invoice) => invoice.id === id);
        if (row && row.softErrors) {
          return row.softErrors.every((code) => allowedSoftErrorCodes.includes(code));
        }
        return true;
      });
      setSelectionModel(newSelection);
    }
  }, [selectionEnabled, filteredData, selectionModel, allowedSoftErrorCodes]);

  // Effect to sanitize selectionModel
  useEffect(() => {
    const validSelection = selectionModel.filter((id) => {
      const row = filteredData.find((invoice) => invoice.invoiceId === id);
      if (row && row.softErrors) {
        return row.softErrors.every((code) => allowedSoftErrorCodes.includes(code));
      }
      return true;
    });

    if (validSelection.length !== selectionModel.length) {
      setSelectionModel(validSelection);
    }
  }, [filteredData, selectionModel, allowedSoftErrorCodes]);

  const handleTabChange = useCallback((event, newValue) => {
    setActiveTab(newValue);
  }, []);

  const handleDeleteInvoices = useCallback(async () => {
    // Bevestiging voor de gebruiker
    if (!window.confirm("Are you sure you want to delete the selected invoices? This action cannot be undone.")) {
      return;
    }

    // Haal de geselecteerde facturen op
    const selectedInvoices = filteredData.filter((invoice) => selectionModel.includes(invoice.id));

    if (selectedInvoices.length === 0) {
      setAlertSeverity("warning");
      setAlertMessage("No invoices selected for deletion.");
      setOpenAlert(true);
      setTimeout(() => setOpenAlert(false), 5000);
      return;
    }

    // console.log("Deleting invoices:", selectedInvoices);
    // return;

    setDeleteInProgress(true);
    const results = [];

    for (const invoice of selectedInvoices) {
      try {
        await axios.delete(`/api/v1/ocr/deleteScannedDocument/${invoice.id}`); // Pas de API endpoint aan indien nodig
        results.push({
          invoiceNumber: invoice.invoiceNumber,
          status: "Success",
        });
      } catch (error) {
        console.error(`Failed to delete invoice ${invoice.invoiceNumber}:`, error);
        results.push({
          invoiceNumber: invoice.invoiceNumber,
          status: "Failed",
          error: error.response?.data?.message || error.message,
        });
      }
    }

    setDeleteInProgress(false);
    // setDeleteResults(results); // Optioneel: Om individuele delete resultaten weer te geven

    // Feedback berichten
    const successCount = results.filter((res) => res.status === "Success").length;
    const failureCount = results.filter((res) => res.status === "Failed").length;

    if (failureCount === 0) {
      setAlertSeverity("success");
      setAlertMessage(`${successCount} invoice(s) deleted successfully.`);
    } else if (successCount === 0) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to delete ${failureCount} invoice(s).`);
    } else {
      setAlertSeverity("warning");
      setAlertMessage(`Deleted ${successCount} invoice(s) successfully. Failed to delete ${failureCount} invoice(s).`);
    }
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 7000);

    // Optioneel: Vernieuw de data om verwijderde facturen te reflecteren
    fetchInvoiceOverviewData();
  }, [filteredData, selectionModel, fetchInvoiceOverviewData]);

  const updateBatchIdOptions = useCallback(() => {
    let batchIds = [];
    if (selectedUser === "any") {
      batchIds = allInvoiceData.map((inv) => inv.batchId);
    } else {
      batchIds = allInvoiceData.filter((inv) => inv.uploadedBy === selectedUser).map((inv) => inv.batchId);
    }
    const uniqueBatchIds = Array.from(new Set(batchIds)).sort((a, b) => a - b);
    const newBatchIdOptions = uniqueBatchIds.map((id) => ({ key: id, value: id }));

    setBatchIdOptions(newBatchIdOptions);

    // Check if the currently selectedBatchId is still in the new options
    const isSelectedBatchIdValid = newBatchIdOptions.some((option) => option.value === selectedBatchId);

    if (!isSelectedBatchIdValid) {
      setSelectedBatchId("any");
    }
    // Else, retain the current selectedBatchId
  }, [selectedUser, allInvoiceData, selectedBatchId]);

  // Use useEffect to call updateBatchIdOptions whenever selectedUser or allInvoiceData changes
  useEffect(() => {
    updateBatchIdOptions();
  }, [updateBatchIdOptions]);

  const [setProcessedByDwsInProgress, setSetProcessedByDwsInProgress] = useState(false);

  const handleSetProcessedByDWS = useCallback(async () => {
    const selectedInvoices = filteredData.filter((invoice) => selectionModel.includes(invoice.id));

    if (selectedInvoices.length === 0) {
      setAlertSeverity("warning");
      setAlertMessage("No invoices selected for processing.");
      setOpenAlert(true);
      setTimeout(() => setOpenAlert(false), 5000);
      return;
    }

    setSetProcessedByDwsInProgress(true);
    const results = [];

    for (const invoice of selectedInvoices) {
      try {
        await axios.put("/api/v1/ocr/setProcessedByDWS", {
          id: invoice.id,
          processedByDWS: true,
        });
        results.push({
          invoiceNumber: invoice.invoiceNumber,
          status: "Success",
        });
      } catch (error) {
        console.error(`Failed to set ProcessedByDWS for invoice ${invoice.invoiceNumber}:`, error);
        results.push({
          invoiceNumber: invoice.invoiceNumber,
          status: "Failed",
          error: error.response?.data?.message || error.message,
        });
      }
    }

    setSetProcessedByDwsInProgress(false);

    const successCount = results.filter((res) => res.status === "Success").length;
    const failureCount = results.filter((res) => res.status === "Failed").length;

    if (failureCount === 0) {
      setAlertSeverity("success");
      setAlertMessage(`Processed ${successCount} invoice(s) successfully.`);
    } else if (successCount === 0) {
      setAlertSeverity("error");
      setAlertMessage(`Failed to process ${failureCount} invoice(s).`);
    } else {
      setAlertSeverity("warning");
      setAlertMessage(`Processed ${successCount} invoice(s) successfully. Failed to process ${failureCount} invoice(s).`);
    }
    setOpenAlert(true);
    setTimeout(() => setOpenAlert(false), 7000);

    // Optional: Refresh the data to reflect changes
    // fetchInvoiceOverviewData();
    setFetchedInvoiceData((prevData) => prevData.filter((invoice) => !selectedInvoices.some((sel) => sel.id === invoice.id)));
  }, [filteredData, selectionModel]);

  return (
    <React.Fragment>
      <Helmet title="Invoice Overviews" />
      <Box sx={{ width: "100%", mb: 0 }}>
        <Collapse in={openAlert}>
          <Alert
            severity={alertSeverity}
            action={
              <Button color="inherit" size="small" onClick={() => setOpenAlert(false)}>
                Close
              </Button>
            }
            sx={{ mb: 2 }}
          >
            {alertMessage}
          </Alert>
        </Collapse>
      </Box>
      <Tabs value={activeTab} onChange={handleTabChange} aria-label="Invoice Tabs">
        <Tab
          label={
            <Box display="flex" alignItems="left" position="relative">
              All Invoices
              <Badge
                badgeContent={tabCounts.all}
                color="primary"
                max={Infinity} // Set a very high value to prevent truncation
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{
                  ml: 3,
                  transform: "translateY(-20%)", // Maintain vertical adjustment
                }}
              />
            </Box>
          }
          sx={{ typography: "h6", fontWeight: "bold" }}
        />
        <Tab
          label={
            <Box display="flex" alignItems="left" position="relative">
              Passed Invoices
              <Badge
                badgeContent={tabCounts.passed}
                color="success"
                max={Infinity} // Set a very high value to prevent truncation
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{
                  ml: 3,
                  transform: "translateY(-20%)",
                }}
              />
            </Box>
          }
          sx={{ typography: "h6", fontWeight: "bold" }}
        />
        <Tab
          label={
            <Box display="flex" alignItems="left" position="relative">
              Soft Error Invoices
              <Badge
                badgeContent={tabCounts.softError}
                color="warning"
                max={Infinity} // Set a very high value to prevent truncation
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{
                  ml: 3,
                  transform: "translateY(-20%)",
                }}
              />
            </Box>
          }
          sx={{ typography: "h6", fontWeight: "bold" }}
        />
        <Tab
          label={
            <Box display="flex" alignItems="left" position="relative">
              Hard Error Invoices
              <Badge
                badgeContent={tabCounts.hardError}
                color="error"
                max={Infinity} // Set a very high value to prevent truncation
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{
                  ml: 3,
                  transform: "translateY(-20%)",
                }}
              />
            </Box>
          }
          sx={{ typography: "h6", fontWeight: "bold" }}
        />
      </Tabs>

      <Divider my={6} />
      <Box
        ref={containerRef}
        sx={{
          width: "100%",
          height: "calc(100vh - 180px)", // Adjust based on your layout
          position: "relative",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PanelGroup direction="horizontal" style={{ height: "100%" }} onUpdate={handlePaneChange} defaultSizes={[70, 30]}>
          <Panel minSize={20} style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
            <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <CardContent sx={{ paddingBottom: 0, flexShrink: 0 }}>
                {/*<Typography variant="h6" gutterBottom>
                    Check Automated Invoices
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Invoices that are processed by the OCR system. Please select your name and batch ID to filter the invoices. After
                    viewing and updating the invoices, you can upload them to DWS using the button Upload selected invoices to DWS. You
                    can view the invoice details by clicking on the row.
                  </Typography>*/}
                {/* Add your filter components here if any */}
              </CardContent>
              {/* <Grid container spacing={0} margin={0} padding={0} style={{ overflow: "auto", flex: 1 }}> */}
              <Grid container spacing={5} sx={{ pr: 2 }}>
                <Grid item xs={2}>
                  <SelectWrapper
                    name="uploadedBy"
                    labelColor="#f64435"
                    label="Uploaded By"
                    options={[
                      { key: "any", value: "Any" },
                      ...(Array.isArray(dwsUserData)
                        ? dwsUserData.map((user) => ({
                            key: user.id,
                            value: user.firstname,
                          }))
                        : []),
                    ]}
                    value={selectedUser}
                    handleChange={handleUserSelectValue}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SelectWrapper
                    name="batchId"
                    label="Batch ID"
                    options={[{ key: "any", value: "Any" }, ...batchIdOptions]}
                    value={selectedBatchId}
                    handleChange={handleBatchIdValue}
                    disabled={selectedUser === "any"} // Disable if no user is selected
                  />
                </Grid>
                <Grid item xs={2}>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Button onClick={handleFilter} sx={{ pl: 6, pr: 6, mt: 4 }} variant="contained">
                      Filter
                    </Button>
                    <Button onClick={handleResetFilter} sx={{ pl: 6, pr: 6, mt: 4 }} variant="outlined">
                      Reset
                    </Button>
                    <Button
                      onClick={() => setSelectionEnabled((prev) => !prev)}
                      sx={{
                        pl: 6,
                        pr: 6,
                        mt: 4,
                        backgroundColor: selectionEnabled ? "error.light" : "inherit", // Light red background when enabled
                        color: selectionEnabled ? "white" : "primary.main", // White text when enabled, blue when not
                        "&:hover": {
                          backgroundColor: selectionEnabled ? "error.main" : "inherit", // Darker red on hover when enabled
                        },
                        minWidth: "auto", // Adjust width to fit the text
                        whiteSpace: "nowrap", // Ensure text stays on one line
                        transition: "background-color 0.3s ease", // Smooth transition for background color
                      }}
                      variant="outlined"
                    >
                      {selectionEnabled ? "Disable Selection" : "Enable All Selection"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={1} pt={1}>
                <DataGrid
                  getRowId={(row) => row.id} // Ensure this matches your unique row identifier
                  disableSelectionOnClick={true}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: false },
                      printOptions: { disableToolbarButton: false },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                  }}
                  density="compact"
                  checkboxSelection
                  rows={filteredData}
                  columns={columns}
                  page={page}
                  onPageChange={(newPage) => setPage(newPage)}
                  pageSize={pageSize}
                  onRowClick={handleRowClick}
                  getRowClassName={(params) => (params.row.invoiceId === highlightedRowId ? "highlighted-row" : "")}
                  onCellKeyDown={handleCellKeyDown}
                  selectionModel={selectionModel}
                  onSelectionModelChange={(newSelection) => {
                    setSelectionModel(newSelection);
                  }}
                  isRowSelectable={isRowSelectable}
                  sx={{
                    height: "667px",
                    width: "100%",
                    borderRadius: 1,
                    backgroundColor: "background.paper",
                    boxShadow: 2,
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main",
                    },
                    "& .highlighted-row": {
                      backgroundColor: "rgba(25, 118, 210, 0.08)",
                      "&:hover": {
                        backgroundColor: "rgba(25, 118, 210, 0.15)",
                      },
                    },
                    "& .disabled-row": {
                      opacity: 0.5,
                      pointerEvents: "none",
                    },
                  }}
                />

                {/* Upload Button */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "20px",
                    marginTop: "10px",
                  }}
                >
                  {activeTab === 3 ? (
                    <Button
                      type="button"
                      onClick={handleDeleteInvoices}
                      variant="contained"
                      color="error" // Gebruik een andere kleur voor delete actie
                      mt={3}
                      my={2}
                      mr={4}
                      disabled={deleteInProgress} // Disable button tijdens delete operatie
                    >
                      {deleteInProgress ? (
                        <Box display="flex" alignItems="center">
                          Deleting...
                          <CircularProgress size={20} sx={{ ml: 1 }} />
                        </Box>
                      ) : (
                        "Delete selected invoices from the system"
                      )}
                    </Button>
                  ) : (
                    <>
                      <Button
                        type="button"
                        onClick={() => setIsConfirmDialogOpen(true)}
                        variant="contained"
                        sx={{
                          backgroundColor: "#ed6c02",
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: "#cc5800",
                          },
                          marginRight: "20px",
                        }}
                        mt={3}
                        my={2}
                        disabled={setProcessedByDwsInProgress}
                      >
                        {setProcessedByDwsInProgress ? (
                          <Box display="flex" alignItems="center">
                            Processing...
                            <CircularProgress size={20} sx={{ ml: 1 }} />
                          </Box>
                        ) : (
                          "Set Processed By DWS"
                        )}
                      </Button>
                      <Button
                        type="button"
                        onClick={handleUploadInvoices}
                        variant="contained"
                        color="primary"
                        mt={3}
                        my={2}
                        mr={2}
                        disabled={uploadInProgress}
                      >
                        {uploadInProgress ? (
                          <Box display="flex" alignItems="center">
                            Uploading...
                            <CircularProgress size={20} sx={{ ml: 1 }} />
                          </Box>
                        ) : (
                          "Upload selected invoices to DWS"
                        )}
                      </Button>
                    </>
                  )}
                </div>
              </Grid>
              {/* </Grid> */}
            </Card>
          </Panel>

          {/* Resize Handle */}
          <PanelResizeHandle
            style={{
              width: "5px",
              cursor: "col-resize",
              backgroundColor: "#ddd",
              borderLeft: "1px solid #aaa",
            }}
          />

          {/* Right Panel */}
          <Panel minSize={0} style={{ overflow: "auto", display: rightPaneSize === 0 ? "none" : "block" }}>
            {rightPaneSize !== 0 && (
              <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
                <Card style={{ height: "100%", position: "relative", overflow: "auto" }}>
                  <CardContent style={{ height: "100%", paddingBottom: 16, overflow: "auto" }}>
                    <IconButton
                      onClick={() => {
                        setRightPaneSize(0);
                        setHighlightedRowId(null);
                        setViewCustomerInvoiceDialog({
                          ...viewCustomerInvoiceDialog,
                          isOpen: false,
                        });
                      }}
                      style={{ position: "absolute", top: 8, right: 8 }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <ViewCheckAutomatedInvoiceDialog
                      viewCustomerInvoiceDialog={viewCustomerInvoiceDialog}
                      setViewCustomerInvoiceDialog={setViewCustomerInvoiceDialog}
                      countryArr={countryData}
                      invoiceStateArr={invoiceStateData}
                      supplierArr={supplierData}
                      currencyArr={currencyData}
                      productTypeArr={productData}
                      entitiesArr={entitiesData}
                      refreshGrid={fetchInvoiceOverviewData}
                      updateInvoice={updateInvoiceInTable}
                      id="dialog-content" // Ensure the dialog has this id for focus management
                      activeTab={activeTab}
                      updateTimestamp={updateTimestamp}
                    />
                  </CardContent>
                </Card>
              </div>
            )}
          </Panel>
        </PanelGroup>

        <UploadStatusModal
          isOpen={isUploadModalOpen}
          onClose={() => setIsUploadModalOpen(false)}
          progress={uploadProgress}
          results={uploadResults}
        />
      </Box>
      <Dialog open={isConfirmDialogOpen} onClose={() => setIsConfirmDialogOpen(false)}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to remove the invoices from the list permantly?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSetProcessedByDWS();
              setIsConfirmDialogOpen(false);
            }}
            color="warning"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default InvoiceOverviewComponent;
